//@ts-nocheck
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'

import { DropdownContainer, DropdownMenu, DropdownMenuEl, DropdownToggle } from './style'

interface DropdownProps {
  options: []
  onChange: (option: string) => void
  defaultOption?: string
}

export const Dropdown: React.FC<DropdownProps> = ({ options, onChange, defaultOption }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(defaultOption)
  const [hasSelection, setHasSelection] = useState(!!defaultOption)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const isSingleOption = options.length <= 1

  const toggleDropdown = () => {
    if (isSingleOption) return

    setIsOpen(!isOpen)
  }

  const handleOptionClick = (option: any) => {
    if (option?.page_id && option?.page_id === 'detail_page') {
      setSelectedOption('Детальная страница')
    } else {
      setSelectedOption(option?.host ?? convertPageNameByHash(option?.uri))
    }
    onChange(option)
    setHasSelection(true)
    toggleDropdown() // закрываем выпадающий список после выбора опции
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false) // закрываем выпадающий список при клике вне его области
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (options.length) {
      setSelectedOption(options[0]?.host ?? convertPageNameByHash(options[0]?.uri))
      onChange(options[0])
    }
  }, [options])

  useEffect(() => {
    // Обновляем выбранную опцию при изменении defaultOption
    if (defaultOption && defaultOption !== selectedOption) {
      setSelectedOption(defaultOption)
      onChange(defaultOption)
      setHasSelection(true)
    }
  }, [defaultOption, onChange, selectedOption])

  const convertPageNameByHash = (name: string) => {
    return name === '/' ? 'Главная' : name
  }

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownToggle
        isActive={isOpen}
        hasSelection={hasSelection}
        onClick={toggleDropdown}
        isSingleOption={isSingleOption}
      >
        {options.length === 0 ? 'Выбрать сайт' : selectedOption || 'Выбор страницы'}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          className="dropdown-icon"
        >
          <path d="M7 10L12 15" stroke="#808080" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12 15L17 10" stroke="#808080" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </DropdownToggle>
      {isOpen && (
        <DropdownMenu>
          {options.map((option, index) => (
            <DropdownMenuEl key={index} onClick={() => handleOptionClick(option)}>
              {option?.page_id && option?.page_id === 'detail_page'
                ? 'Детальная страница'
                : option?.host ?? convertPageNameByHash(option?.uri)}
            </DropdownMenuEl>
          ))}
        </DropdownMenu>
      )}
    </DropdownContainer>
  )
}
