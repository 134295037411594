import * as yup from 'yup'

import { emailValidation, passwordValidation } from './globalSchema'

export const registrationStepMainDataValidationSchema = yup.object().shape({
  firstName: yup.string().required('Введите имя'),
  lastName: yup.string().required('Введите фамилию'),
  company: yup.string().required('Введите компанию'),
  position: yup.string().required('Введите должность'),
  phone: yup
    .string()
    .test('len', 'Введите телефон полностью', (val: any) => {
      const val_length_without_dashes = val?.replace(/-|_/g, '')?.length
      return val_length_without_dashes === 16
    })
    .required('Введите телефон'),
  agreement: yup.bool().oneOf([true], 'Для продолжения необходимо согласиться с текстом договора')
})

export const registrationStepEmailValidationSchema = yup.object().shape({
  email: emailValidation,
  agreement: yup.bool().oneOf([true], 'Обязательное поле')
})

export const registrationStepPasswordValidationSchema = passwordValidation
