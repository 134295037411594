export const formatPhoneNumber = (phone: string): string | null => {
  if (phone) {
    const cleaned = phone.replace(/\D/g, '')

    if (cleaned.length === 11 && (cleaned.startsWith('8') || cleaned.startsWith('7'))) {
      return `+7 (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7, 9)}-${cleaned.slice(9, 11)}`
    } else if (cleaned.length === 10) {
      return `+7 (${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 8)}-${cleaned.slice(8, 10)}`
    } else if (cleaned.length === 12 && cleaned.startsWith('79')) {
      return `+${cleaned.slice(0, 1)} (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(
        7,
        9
      )}-${cleaned.slice(9, 11)}`
    }
    return phone
  }

  return null
}
