import styled from '@emotion/styled/macro'

export const CookieInner = styled.div`
  position: absolute;
  z-index: 10;
  bottom: 4rem;
  right: 4rem;
  background: #f8f8f8;
  padding: 1.6rem;
  border-radius: 1.2rem;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.body.p5};
  max-width: 51.6rem;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 15%);

  button {
    width: auto;
    min-width: 5.4rem;
  }
`
