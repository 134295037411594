import * as yup from 'yup'

export const editAddressEdoValidationSchema = yup.object().shape({
  email: yup.string().required('Введите email')
})

export const editAddressValidationSchema = yup.object().shape({
  name: yup.string().required('Введите название организации'),
  receiver: yup.string().required('Введите получателя'),
  // phone: yup.string().required('Введите телефон'),
  address: yup.string().required('Введите адрес'),
  email: yup.string().required('Введите email')
})
