import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js'
import React, { FC, useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { useGetBillingAnalyticsDailyByIdMutation } from '../../api/userApi'
import i from '../../assets/icons/i.svg'
import { CreateSoonText, CreateSoonWrapper, RedText } from '../../assets/styles/global'
import { selectUserBilling } from '../../store/userBillingSlice'
import { selectUser } from '../../store/userSlice'
import { Image } from '../../ui/image'
import { Loader } from '../../ui/loader'
import { data as lineData, htmlLegendPlugin, options as lineOptions } from './chart-settings'
import { AnalyticsInner, Block, ChartContainer, Item, Rating, Title as TitleText, Value } from './style'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

export const Analytics: FC<{ isDevelop?: boolean }> = ({ isDevelop }) => {
  const [billingAnalyticsDailyData, setBillingAnalyticsDailyData] = useState<any>({ labels: [], datasets: [] })
  const { billingSelectedDealerId } = useSelector(selectUserBilling)
  const user = useSelector(selectUser)
  let location = useLocation()
  const navigate = useNavigate()
  const urlParams = new URLSearchParams(location?.search)
  const show_analytic = urlParams.get('show_analytic')

  const [getBillingAnalyticsDailyById, { data, isSuccess, isLoading, isError }] =
    useGetBillingAnalyticsDailyByIdMutation()

  useEffect(() => {
    ;(async () => {
      if (billingSelectedDealerId && !data) {
        await getBillingAnalyticsDailyById({ id: billingSelectedDealerId || '', userId: user?.id || '' })
      }
    })()
  }, [billingSelectedDealerId, data])

  useEffect(() => {
    // TODO: перенести поля объектов на бек, чтобы убрать этот ужас (borderColor, backgroundColor, borderWidth)
    const lineData = {
      labels: data?.labels || [],
      datasets:
        data?.datasets
          ?.map((item) => {
            if (item?.label === 'Количество посетителей') {
              return {
                label: item?.label,
                data: item?.data,
                borderColor: 'rgb(85, 161, 229)',
                backgroundColor: 'rgba(85, 161, 229, 0.5)',
                borderWidth: '2'
              }
            }
            if (item?.label === 'Количество заявок') {
              return {
                label: item?.label,
                data: item?.data,
                borderColor: 'rgb(109, 189, 191)',
                backgroundColor: 'rgba(109, 189, 191, 0.5)',
                borderWidth: '2'
              }
            }
            if (item?.label === 'Количество начатых оплат') {
              return {
                label: item?.label,
                data: item?.data,
                borderColor: 'rgb(237, 110, 133)',
                backgroundColor: 'rgba(237, 110, 133, 0.5)',
                borderWidth: '2'
              }
            }
          })
          ?.filter((notUndefined) => notUndefined !== undefined) || []
    }

    setBillingAnalyticsDailyData(lineData)
  }, [data])

  const goToAnalytics = () => {
    navigate('/analytics')
  }

  return (
    <CreateSoonWrapper>
      <AnalyticsInner>
        <TitleText onClick={goToAnalytics}>
          Аналитика
          {/* NOTE: пример графика не удалять */}
          {/* <Rating>
            4,8/5
            <Image src={i} alt="i" />
          </Rating> */}
        </TitleText>
        <ChartContainer>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <div id="legend-container"></div>
              <Line options={lineOptions} data={billingAnalyticsDailyData} plugins={[htmlLegendPlugin]} />
            </>
          )}
        </ChartContainer>
        <RedText>Раздел работает в тестовом режиме</RedText>
      </AnalyticsInner>
    </CreateSoonWrapper>
  )
}
