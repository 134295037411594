import { FC, ReactNode, useEffect } from 'react'
import { useSelector } from 'react-redux'

import close from '../../assets/icons/close.svg'
import { selectUserBilling } from '../../store/userBillingSlice'
import { getLocalStorageValue, setLocalStorageValue } from '../../utils/localStorageHelpers'
import { Image } from '../image'
import { Close, Modal, ModalInner } from './style'

export const Popup: FC<{
  closePopup: (isShow: boolean) => void
  children: ReactNode
  background?: boolean
  textAlign?: string
  isDanger?: boolean
  isHidden?: boolean
  className?: string
  isCloseHidden?: boolean
}> = ({
  closePopup,
  children,
  background = false,
  textAlign,
  isDanger,
  isHidden,
  className,
  isCloseHidden = false
}) => {
  const { billingSelectedDealerId } = useSelector(selectUserBilling)
  const setTimeInLocalStorage = () => {
    let arr = getLocalStorageValue('time')
    if (arr && arr.length > 0) {
      const dealer = arr.find((item: { dealer_id: string; date: Date }) => item.dealer_id === billingSelectedDealerId)
      if (dealer) {
        dealer.date = new Date()
      } else {
        arr.push({ dealer_id: billingSelectedDealerId, date: new Date() })
      }
      setLocalStorageValue('time', arr)
    } else {
      setLocalStorageValue('time', [{ dealer_id: billingSelectedDealerId, date: new Date() }])
    }
  }

  return (
    <Modal
      onClick={(e) => {
        !isDanger && closePopup(false)
      }}
      className={className}
    >
      <ModalInner
        background={background}
        onClick={(e) => {
          e.stopPropagation()
        }}
        textAlign={textAlign}
      >
        {!isHidden && !isCloseHidden ? (
          <Close
            onClick={() => {
              if (isDanger) {
                setTimeInLocalStorage()
              }
              closePopup(false)
            }}
          >
            <Image src={close} alt="close" />
          </Close>
        ) : null}
        {children}
      </ModalInner>
    </Modal>
  )
}
