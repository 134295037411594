import styled from '@emotion/styled/macro'

import close from '../../assets/icons/close_gray.svg'

export const ContainerHistory = styled.div<{ bgColor?: string }>`
  background: ${({ bgColor }) => {
    return bgColor ? bgColor : '#ffffff'
  }};
  padding: 24px;
  border-radius: 10px;
  margin-bottom: 12px;

  &.desktop {
    @media (max-width: 1144px) {
      display: none;
    }
  }

  @media (max-width: 1144px) {
    padding: 0;
    background: transparent;
    margin-bottom: 16px;
  }
`

export const ContainerHistoryMobile = styled.div`
  display: none;
  margin-bottom: 16px;

  @media (max-width: 1144px) {
    display: block;
  }
`

export const ContainerHeaderMobile = styled.div`
  background: #e2e4eb;
  padding: 16px;
  border-radius: 10px 10px 0 0;
`

export const ContainerBodyMobile = styled.div`
  background: #ffffff;
  padding: 16px;
  border-radius: 0 0 10px 10px;
`

export const Title = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.48px;
  color: #000000;
`

export const InputDate = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
  color: #232323;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  padding: 10px 8px;
  background: #ffffff;
  min-height: 41px;
`

export const Group = styled.div<{ width?: string }>`
  display: flex;
  align-items: center;
  width: ${({ width }) => {
    return width ? width : '20%'
  }};

  &.datepicker-operations {
    margin-top: 24px;
    flex-flow: row wrap;
    row-gap: 24px;

    .react-datepicker-wrapper {
      width: 100% !important;
      max-width: 247px;
      margin-right: 24px;
      height: 41px;

      input {
        width: 100%;
      }

      @media (max-width: 1144px) {
        margin-right: 0;
        max-width: 100%;
      }
    }
  }

  @media (max-width: 1144px) {
    flex-direction: column;
    align-items: flex-start;
  }

  &.mobile-group {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: auto;
  }
`

export const GroupColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const CloseButton = styled.button`
  width: 24px;
  height: 24px;
  background: url(${close});
  padding: 0;
  border: none;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`

export const TextTable = styled.span<{ size?: string; bold?: boolean; width?: string; color?: string }>`
  font-size: ${({ size }) => {
    return size ? size : '12px'
  }};
  width: ${({ width }) => {
    return width ? width : 'auto'
  }};
  min-width: 60px;
  line-height: 20px;
  font-weight: ${({ bold }) => {
    return bold ? 'bold' : 'normal'
  }};
  color: ${({ color }) => {
    return color ? color : '#232323'
  }};
  display: flex;
  align-items: center;

  @media (max-width: 1144px) {
    min-width: auto;
  }

  &.error {
    color: #e00909;
  }

  &.success {
    color: #3db014;
  }
`
