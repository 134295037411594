import { calcNoNDS } from './calcNoNDS'

export const calcPeriodPaymentAmount = (
  total: number | string,
  periodPayment: number | string,
  periods: number,
  ndsPercent: number
): number => {
  const periodPaymentAmount = Number(periodPayment) * periods + (Number(total) < 0 ? Math.abs(Number(total)) : 0)
  return Math.round(periodPaymentAmount)
}
