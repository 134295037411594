import React, { FC, useEffect, useState } from 'react'
import { BiRuble } from 'react-icons/bi'

import { ModulesPackages, SupportPackages } from '../../@types/billingUserTypes'
import { useGetModulePackagesMutation, useGetSupportPackagesMutation } from '../../api/billingUserApi'
import { GreyText } from '../../assets/styles/global'
import { Loader } from '../../ui/loader'
import { Head, Row, Table, TablePrice, TableText, Text, Total } from '../../ui/popup/style'
import { calcNoNDS } from '../../utils/calcNoNDS'
import { excludeNDS } from '../../utils/excludeNDS'
import { numberWithSpaces } from '../../utils/numberWithSpaces'

export const ClientPackages: FC<any> = ({ billingSelectedDealerId, billingClientBalance, totalAmountOn = false }) => {
  const [modulePackages, setModulePackages] = useState<[]>()
  const [supportPackages, setSupportPackages] = useState<[]>()
  const [getModulePackages, { data: dataModulePackages, isLoading: dataModulePackagesLoading }] =
    useGetModulePackagesMutation()
  const [getSupportPackages, { data: dataSupportPackages, isLoading: dataSupportPackagesLoading }] =
    useGetSupportPackagesMutation()

  useEffect(() => {
    if (billingSelectedDealerId) {
      billingSelectedDealerId && getModulePackages({ id: billingSelectedDealerId })
      billingSelectedDealerId && getSupportPackages({ id: billingSelectedDealerId })
    }
  }, [billingSelectedDealerId])

  useEffect(() => {
    setModulePackages(dataModulePackages?.items)
    setSupportPackages(dataSupportPackages?.items)
  }, [dataModulePackages, dataSupportPackages])

  return (modulePackages && modulePackages?.length > 0) || (supportPackages && supportPackages?.length > 0) ? (
    <Table>
      <Head className="head">
        <div>Наименование</div>
        <div>Стоимость</div>
      </Head>
      {modulePackages &&
        modulePackages.map((item: ModulesPackages) => {
          return (
            <Row>
              <TableText>Лицензия на {item.dashboard_name}</TableText>
              <TablePrice>
                {numberWithSpaces(Number(item.sum).toFixed(2))}
                <BiRuble />
              </TablePrice>
            </Row>
          )
        })}
      {supportPackages &&
        supportPackages.map((item: SupportPackages) => {
          return (
            <Row>
              <TableText>Пакет работ {item.name}</TableText>
              <div>
                {numberWithSpaces(item.price)?.toLocaleString()}
                <BiRuble />
              </div>
            </Row>
          )
        })}
      {Number(billingClientBalance?.total) < 0 ? (
        <Row>
          <TableText>Задолженность</TableText>
          <div>
            {numberWithSpaces(
              calcNoNDS(Math.abs(Number(billingClientBalance?.total)), billingClientBalance?.percent_nds ?? 0).toFixed(
                2
              )
            )?.toLocaleString()}
            <BiRuble />
          </div>
        </Row>
      ) : null}
      <Row>
        <div>НДС {billingClientBalance?.percent_nds}%</div>
        <TablePrice>
          {numberWithSpaces(
            excludeNDS(
              Math.abs(Number(billingClientBalance?.recommend_payment)),
              billingClientBalance?.percent_nds ?? 0
            ).toFixed(2)
          )?.toLocaleString()}
          <BiRuble />
        </TablePrice>
      </Row>

      {totalAmountOn && (
        <Total>
          <div>Итого</div>
          <TablePrice>
            {numberWithSpaces(Number(billingClientBalance?.recommend_payment).toFixed(2)).toLocaleString()}
            <BiRuble />
          </TablePrice>
        </Total>
      )}
    </Table>
  ) : (
    <GreyText>{dataModulePackagesLoading ? <Loader /> : <Text> У вас нет подключенных пакетов</Text>}</GreyText>
  )
}
