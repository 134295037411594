import styled from '@emotion/styled/macro'

export const Title = styled.div`
  ${({ theme }) => theme.body.p1};
  color: ${({ theme }) => theme.colors.accent};
  font-family: 'Helvetica', sans-serif;
  margin-bottom: 1.1rem;
  @media ${({ theme }) => theme.media.medium} {
    font-size: 1.6rem;
  }
`

export const Wrap = styled.div<{ bg?: string }>`
  background: ${({ bg }) => {
    return bg ? bg : 'rgba(34, 51, 97, 0.1)'
  }};
  border-radius: 1rem;
  padding: 2.4rem 10rem 2.4rem 2.4rem;
  margin-bottom: 1.6rem;
  @media ${({ theme }) => theme.media.medium} {
    padding: 2.4rem 5rem 0.7rem 1.6rem;
  }
`

export const HeaderContactsInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: 60px;

  @media ${({ theme }) => theme.media.small} {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`

export const Field = styled.div`
  font-size: 2rem;
  font-family: 'Helvetica', sans-serif;
  flex: 1;

  span {
    color: #888888;
  }

  @media ${({ theme }) => theme.media.tablet} {
    font-size: 1.6rem;
  }
`

export const ContactLink = styled.a`
  &[href] {
    cursor: pointer;
  }
`
