export const getLocalStorageValue = (name: string) => {
  try {
    const value = window.localStorage.getItem(name)
    return value?.length ? JSON.parse(value) : null
  } catch (error) {
    console.log('getLocalStorageValue', error)
  }
}

export const setLocalStorageValue = (name: string, value: any) => {
  try {
    window.localStorage.setItem(name, JSON.stringify(value))
  } catch (error) {
    console.log('setLocalStorageValue', error)
  }
}

export const removeLocalStorageValue = (name: string) => {
  try {
    return window.localStorage.removeItem(name)
  } catch (error) {
    console.log('removeLocalStorageValue', error)
  }
}
