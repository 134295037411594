import { css } from '@emotion/react/macro'
import styled from '@emotion/styled/macro'

import { baseTheme } from './theme'

export const globalStyle = css`
  @font-face {
    font-family: 'Helvetica';
    src: url('./../fonts/helvetica_regular.otf') format('otf');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Helvetica';
    src: url('./../fonts/helvetica_bold.otf') format('otf');
    font-weight: bold;
  }

  ::selection {
    background-color: #000000;
    color: #ffffff;
  }

  :root {
    font-size: 10px;
  }

  #root {
    height: 100%;
  }

  body {
    font-family: 'Open Sans', sans-serif;
    color: ${baseTheme.colors.basic};
  }

  h1 {
    ${baseTheme.title.h1};
  }

  h2 {
    ${baseTheme.title.h2};
  }

  h3 {
    ${baseTheme.title.h3};
  }

  input {
    font-family: 'Open sans', sans-serif;
    color: ${baseTheme.colors.basic};
    ${baseTheme.body.input}
    cursor: pointer;
    transition: background-color 0.3s linear, color 0.3s linear;
    outline: none;
    background: none;
    padding-right: 3rem;
    &:focus {
      outline: none;
    }
    box-shadow: inset 0 0 0 50px #fff;
  }

  .section {
    position: relative;
    max-width: 170rem;
    padding: 0 calc(10rem);
    width: 100%;
    margin: 0 auto;
    min-height: calc(100% - 7.6rem);

    @media ${baseTheme.media.medium} {
      padding: 0;
    }
  }

  .shadow {
    box-shadow: 0 1rem 3rem 0 rgba(#003b6a, 0.1);
  }

  .slick-dots {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: auto;
    bottom: auto;
    top: calc(50% - 100px);
    right: -30px;
  }
  .slick-dots li {
    width: auto;
    height: auto;
  }
  .slick-dots li button {
    width: 8px;
    height: 8px;
    box-sizing: border-box;
    border: 1px solid #223361;
    border-radius: 50%;
    background: #ffffff;
    padding: 0;
    margin: 4px 0;
  }
  .slick-dots li button:before {
    content: none;
  }
  .slick-dots li.slick-active button {
    background: #223361;
  }

  .slider-home .slick-dots {
    left: 0;
    right: 0;
    flex-direction: row;
    bottom: -25px;
    align-items: center;
    width: 100%;
    height: max-content;
    top: auto;
    justify-content: center;
  }
`

export const OauthFormContainer = styled.div<{ noTopMargin?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 45.6rem;
  margin: ${({ theme, noTopMargin }) => `${noTopMargin ? '0' : '54px'} auto 0`};

  & form {
    width: 100%;
  }

  @media ${({ theme }) => theme.media.medium} {
    max-width: none;
    margin: ${({ theme, noTopMargin }) => `${noTopMargin ? '0' : '16px'} auto 0`};
  }
  form {
    h3 {
      margin-bottom: 3.2rem;
    }
  }
`

export const Container = styled.div<{ isFixed?: boolean; bg?: string; isAnalytics?: boolean }>`
  background-color: ${({ theme, bg }) => (bg ? bg : theme.colors.grey1)};
  width: 100%;
  padding: 5.6rem 8.1rem 6rem;
  overflow: auto;
  overflow-x: hidden;

  .metrika-alert > div {
    position: relative;
    top: 0;
    width: 100%;
    text-align: center;
  }

  .loader > div {
    position: absolute;
    height: 100%;
    z-index: 2;
    width: 100%;
    left: 0;
  }

  &.full-height {
    min-height: 100vh;
  }

  &.container-tariffs {
    overflow-x: hidden;

    .slick-dots {
      display: block !important;
      top: auto;
      right: auto;
      margin-top: 16px;
      width: 100%;
    }
  }

  @media ${({ theme }) => theme.media.large} {
    padding: 1.6rem;
  }

  @media ${({ theme }) => theme.media.medium} {
    ${({ isFixed, isAnalytics }) => {
      if (isAnalytics) return 0
      return isFixed ? 'padding-top: 26.5rem;' : 'padding-top: 9rem;'
    }}
`

export const Bold = styled.span`
  font-weight: 600;
`

export const RedText = styled.span<{ bold?: boolean }>`
  color: ${({ theme }) => theme.colors.error};
  ${({ bold }) => bold && `font-weight: 600`};
  display: flex;
  align-items: center;
`

export const GreenText = styled.span<{ bold?: boolean }>`
  color: ${({ theme }) => theme.colors.success};
  ${({ bold }) => bold && `font-weight: 600`};
  display: flex;
  align-items: center;
`

export const GreyText = styled.span`
  color: ${({ theme }) => theme.colors.grey4};
`

export const CreateSoonWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const CreateSoonText = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4px);
  border-radius: 0.8rem;

  p {
    font-size: 2.4rem;
    line-height: 2.8rem;
    color: #223361;
    font-family: 'Helvetica', sans-serif;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    font-weight: 400;
    text-align: center;
    padding: 2.5rem 1.6rem 1.7rem;
  }
`
