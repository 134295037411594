export const ROUTES = {
  home: '/',
  registration: '/registration',
  login: '/',
  logoutAndRedirectInTracker: '/logout_back_tracker',
  resetPassword: '/reset_password',
  restorePassword: '/restore_password',
  dashboard: '/home',
  settings: '/settings',
  analytics: '/analytics/graphics',
  contacts: '/contacts',
  billingContractFileById: '/files',
  operationsHistory: 'operations-history',
  documents: '/documents',
  tariffs: '/tariffs',
  notifications: '/notifications',
  internalLogin: '/internal-login',
  metrika: '/analytics/metrika'
}
