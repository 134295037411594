import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { setLocalStorageValue } from '../../utils/localStorageHelpers'
import { Title } from './style'

export const InternalLogin = () => {
  const navigate = useNavigate()

  useEffect(() => {
    setLocalStorageValue('isInternal', true)

    setTimeout(() => {
      navigate('/')
    }, 100)
  }, [])

  return <Title>Идет переадрессация...</Title>
}
