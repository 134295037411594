// @ts-nocheck

import { forwardRef, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { BiRuble } from 'react-icons/bi'
import { useSelector } from 'react-redux'

import { DropdownElement } from '../../@types/dropdown'
import { FilterHistory } from '../../@types/history'
import { useGetTransactionsByIDMutation, useGetTypeTransactionsMutation } from '../../api/historyOperationsApi'
import { ReactComponent as IInCircle } from '../../assets/icons/iInCircle.svg'
import { Container } from '../../assets/styles/global'
import { selectUserBilling } from '../../store/userBillingSlice'
// import { Dropdown } from '../../ui/dropdown/Dropdown'
import { InfoText } from '../../ui/infoText'
import { StyledText } from '../../ui/infoText/style'
import { convertDate, getTimeByDateISO } from '../../utils/convertDate'
import { transformDropdownList } from '../../utils/transformDropdownList'
import { NotificationListClients } from '../settings/style'
import {
  CloseButton,
  ContainerBodyMobile,
  ContainerHeaderMobile,
  ContainerHistory,
  ContainerHistoryMobile,
  Group,
  GroupColumn,
  InputDate,
  TextTable,
  Title
} from './style'

function OperationsHistory() {
  const [getTypeTransactions, { data: transactions }] = useGetTypeTransactionsMutation()

  const [getTransactionsByID, { data: transactionsList }] = useGetTransactionsByIDMutation()

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(new Date())
  const [transformedTransactionsList, setTransformedTransactionsList] = useState<DropdownElement[] | []>([])
  const [filter, setFilter] = useState<FilterHistory | {}>({})

  const { billingSelectedDealer } = useSelector(selectUserBilling)

  const days = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб']
  const months = [
    'январь',
    'февраль',
    'март',
    'апрель',
    'май',
    'июнь',
    'июль',
    'август',
    'сентябрь',
    'октябрь',
    'ноябрь',
    'декабрь'
  ]
  const locale = {
    localize: {
      day: (n: any) => days[n],
      month: (n: any) => months[n]
    },
    formatLong: {
      date: () => 'mm/dd/yyyy'
    }
  }

  useEffect(() => {
    ;(async () => {
      const date = new Date()

      setStartDate(date.setMonth(date.getMonth() - 3))

      setFilter({
        from: new Date(startDate).toISOString(),
        to: new Date(endDate).toISOString(),
        operation_type_id: transformedTransactionsList.find((el) => el?.isActive)?.id
      })
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      await getTypeTransactions()
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      if (billingSelectedDealer && !!Object.keys(filter).length) {
        getHistoryList()
      }
    })()
  }, [billingSelectedDealer, filter])

  useEffect(() => {
    ;(async () => {
      if (transactions) {
        setTransformedTransactionsList(transformDropdownList(transactions))
      }
    })()
  }, [transactions])

  useEffect(() => {
    setFilter({
      from: new Date(startDate).toISOString(),
      to: new Date(endDate).toISOString(),
      operation_type_id: transformedTransactionsList.find((el) => el?.isActive)?.id
    })
  }, [transformedTransactionsList])

  const getHistoryList = async () => {
    const params = new URLSearchParams()

    params.append('filter', JSON.stringify(filter))

    await getTransactionsByID({
      id: billingSelectedDealer.id,
      params: params.toString()
    })
  }

  const minYearDateInterval = (date: Date) => {
    const newDate = new Date(date)
    newDate?.setMonth(date?.getMonth() - 12)

    return newDate
  }

  const changeStartDate = async (update: any) => {
    setStartDate(update)
    const params = new URLSearchParams()

    params.append(
      'filter',
      JSON.stringify({
        from: new Date(update).toISOString(),
        to: new Date(endDate).toISOString(),
        operation_type_id: transformedTransactionsList.find((el) => el?.isActive)?.id
      })
    )

    await getTransactionsByID({
      id: billingSelectedDealer.id,
      params: params.toString()
    })
  }

  const changeEndDate = async (update: any) => {
    setEndDate(update)
    const params = new URLSearchParams()

    params.append(
      'filter',
      JSON.stringify({
        from: new Date(startDate).toISOString(),
        to: new Date(update).toISOString(),
        operation_type_id: transformedTransactionsList.find((el) => el?.isActive)?.id
      })
    )

    await getTransactionsByID({
      id: billingSelectedDealer.id,
      params: params.toString()
    })
  }

  const InputByDateFrom = forwardRef(({ _, onClick }: any, ref: any) => (
    <div>
      <InputDate onClick={onClick} ref={ref}>
        {startDate ? convertDate(startDate, '.') : ''}
        {startDate && <CloseButton onClick={() => setStartDate(null)} />}
      </InputDate>
    </div>
  ))

  const InputByDateTo = forwardRef(({ _, onClick }: any, ref: any) => (
    <div>
      <InputDate onClick={onClick} ref={ref}>
        {endDate ? convertDate(endDate, '.') : ''}
        {endDate && <CloseButton onClick={() => setEndDate(null)} />}
      </InputDate>
    </div>
  ))
  return (
    <Container className="full-height">
      {new Date(billingSelectedDealer?.active_from)?.getTime() - new Date('10.01.2023').getTime() < 0 ? (
        <NotificationListClients>
          <IInCircle />
          Историю финансовых операций до 01.10.2023 вы можете запросить у вашего аккаунт-менеджера
        </NotificationListClients>
      ) : null}
      <ContainerHistory>
        <Title>История финансовых операций</Title>

        <Group className="datepicker-operations" width="100%">
          <DatePicker
            selected={startDate}
            onChange={(update: any) => {
              changeStartDate(update)
            }}
            minDate={minYearDateInterval(endDate)}
            isClearable={false}
            className={'udp'}
            selectsStart
            customInput={<InputByDateFrom />}
            locale={locale}
            calendarStartDay={1}
          />

          <DatePicker
            selected={endDate}
            onChange={(update: any) => {
              changeEndDate(update)
            }}
            minDate={startDate}
            maxDate={new Date()}
            isClearable={false}
            className={'udp'}
            selectsEnd
            customInput={<InputByDateTo />}
            locale={locale}
            calendarStartDay={1}
          />

          {/* <Dropdown values={transformedTransactionsList} setValues={setTransformedTransactionsList} /> */}
        </Group>
      </ContainerHistory>

      <ContainerHistory bgColor="#E2E4EB" className="desktop">
        <Group width="100%">
          <TextTable width="20%" bold={true} size="14px" color="#223361">
            Дата и время
          </TextTable>
          <TextTable width="10%" bold={true} size="14px" color="#223361">
            Тип
          </TextTable>
          <TextTable width="20%" bold={true} size="14px" color="#223361">
            Операция
          </TextTable>
          <TextTable width="30%" bold={true} size="14px" color="#223361">
            Описание
          </TextTable>
          <TextTable width="20%" size="14px" bold={true} color="#223361">
            Сумма
          </TextTable>
        </Group>
      </ContainerHistory>

      {transactionsList?.map(
        (transaction) =>
          transaction?.sum !== 0 && (
            <div key={transaction.id}>
              <ContainerHistory className="desktop">
                <Group width="100%">
                  <Group width="20%">
                    <TextTable style={{ marginRight: '8px' }}>
                      {transaction?.dt ? convertDate(transaction?.dt, '.') : 'Дата отсутсвует'}
                    </TextTable>
                    <TextTable>{transaction?.dt ? getTimeByDateISO(transaction?.dt) : 'Время отсутсвует'}</TextTable>
                  </Group>
                  <TextTable width="10%" className={transaction?.sum > 0 ? 'success' : 'error'}>
                    {transaction?.sum > 0 ? 'Пополнение' : 'Списание'}
                  </TextTable>
                  <TextTable width="20%">{transaction?.operationType?.display}</TextTable>
                  <TextTable width="30%">{`${transaction?.operationType?.display} по запросу #${transaction?.id}`}</TextTable>
                  <TextTable width="20%" size="14px" bold={true}>
                    {transaction?.sum} <BiRuble />
                  </TextTable>
                </Group>
              </ContainerHistory>

              <ContainerHistoryMobile className="mobile">
                <ContainerHeaderMobile>
                  <Group width="100%" className="mobile-group">
                    <TextTable size="14px" bold={true} color="#223361">
                      Дата и время
                    </TextTable>
                    <Group className="mobile-group">
                      <TextTable style={{ marginRight: '8px', minWidth: '63px' }}>
                        {transaction?.dt ? convertDate(transaction?.dt, '.') : 'Дата отсутсвует'}
                      </TextTable>
                      <TextTable>{transaction?.dt ? getTimeByDateISO(transaction?.dt) : 'Время отсутсвует'}</TextTable>
                    </Group>
                  </Group>
                  <Group width="100%" className="mobile-group" style={{ marginTop: '8px' }}>
                    <TextTable size="14px" bold={true} color="#223361">
                      Сумма
                    </TextTable>
                    <TextTable width="20%" size="14px" bold={true} style={{ textAlign: 'right' }}>
                      {transaction?.sum} <BiRuble />
                    </TextTable>
                  </Group>
                </ContainerHeaderMobile>
                <ContainerBodyMobile>
                  <GroupColumn>
                    <TextTable size="14px" bold={true} color="#223361">
                      Операция
                    </TextTable>
                    <TextTable>{transaction?.operationType?.display}</TextTable>
                  </GroupColumn>
                  <GroupColumn style={{ marginTop: '16px' }}>
                    <TextTable size="14px" bold={true} color="#223361">
                      Описание
                    </TextTable>
                    <TextTable>{`${transaction?.operationType?.display} по запросу #${transaction?.id}`}</TextTable>
                  </GroupColumn>
                </ContainerBodyMobile>
              </ContainerHistoryMobile>
            </div>
          )
      )}
    </Container>
  )
}

export default OperationsHistory
