import { FC } from 'react'

import { WarningInner } from './style'

type WarningProps = {
  children: any
  status: 'success' | 'error'
}

export const Warning: FC<WarningProps> = ({ children, status }) => {
  return <WarningInner status={status}>{children}</WarningInner>
}
