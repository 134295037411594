import styled from '@emotion/styled/macro'

export const CardInner = styled.a<{ icon: string }>`
  ${({ theme }) => theme.title.h2};
  background: ${({ theme, icon }) => theme.colors.white + ` url(${icon}) no-repeat calc(100% - 2.7rem) 50%`};
  box-shadow: 0 0.4rem 1.5rem rgba(208, 210, 218, 0.3);
  border-radius: 0.8rem;
  padding: 1.5rem 10rem 1.6rem 1.6rem;
  position: relative;
  overflow: hidden;
  flex: 1;
  display: inline-flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 11.3rem;
  cursor: pointer;
  margin-bottom: 2.4rem;
  @media ${({ theme }) => theme.media.extraLarge} {
    margin-bottom: 0;
  }
  @media ${({ theme }) => theme.media.large} {
    width: 100%;
    margin-bottom: 2.4rem;
  }

  &:first-of-type + div {
    @media ${({ theme }) => theme.media.extraLarge} {
      margin-bottom: 2.4rem;
    }
  }
`

export const Title = styled.div`
  ${({ theme }) => theme.title.h2};
  color: ${({ theme }) => theme.colors.accent};
  margin-bottom: 0.9rem;
  font-weight: 400;
`

export const Text = styled.div`
  ${({ theme }) => theme.body.p2};
  margin-bottom: 1.2rem;
  position: relative;
  z-index: 1;
`

export const Connected = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.body.p2};
  position: relative;
  z-index: 1;

  span {
    @media ${({ theme }) => theme.media.medium} {
      font-size: 1.2rem;
      line-height: normal;
    }
  }
  img {
    margin-right: 5px;
    position: static;
    @media ${({ theme }) => theme.media.medium} {
      height: auto;
    }
  }
`
