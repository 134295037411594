export const options = {
  responsive: true,
  layout: {
    autoPadding: false,
    padding: 0
  },
  plugins: {
    htmlLegend: {
      // ID of the container to put the legend in
      containerID: 'legend-container'
    },
    legend: {
      display: false,
      position: 'bottom',
      fullSize: false
    },
    title: {
      display: false
    }
  },
  scales: {
    y: {
      min: 0,
      ticks: {
        beginAtZero: true,
        stepSize: 10
      }
    }
  }
}

const getOrCreateLegendList = (chart: any, id: string) => {
  const legendContainer = document.getElementById(id)
  let listContainer = legendContainer?.querySelector('ul')

  if (!listContainer) {
    listContainer = document.createElement('ul')
    listContainer.style.display = 'flex'
    listContainer.style.flexDirection = 'column'
    listContainer.style.gap = '4px'
    listContainer.style.margin = '0px 0px 10px 0px'
    listContainer.style.padding = '0px'

    legendContainer?.appendChild(listContainer)
  }

  return listContainer
}

export const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart: any, args: any, options: any) {
    const ul = getOrCreateLegendList(chart, options?.containerID)

    // Remove old legend items
    while (ul?.firstChild) {
      ul?.firstChild?.remove()
    }
    // Reuse the built-in legendItems generator
    const items = chart?.options?.plugins?.legend?.labels?.generateLabels(chart)

    items.forEach((item: any) => {
      const li = document.createElement('li')
      li.style.alignItems = 'center'
      li.style.cursor = 'pointer'
      li.style.display = 'flex'
      li.style.flexDirection = 'row'
      li.style.marginLeft = '10px'

      li.onclick = () => {
        const { type } = chart?.config
        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart?.toggleDataVisibility(item?.index)
        } else {
          chart?.setDatasetVisibility(item?.datasetIndex, !chart?.isDatasetVisible(item?.datasetIndex))
        }
        chart?.update()
      }

      // Color box
      const boxSpan = document.createElement('span')
      boxSpan.style.background = item?.fillStyle
      boxSpan.style.borderColor = item?.strokeStyle
      boxSpan.style.borderWidth = item?.lineWidth + 'px'
      boxSpan.style.display = 'inline-block'
      boxSpan.style.flexShrink = '0px'
      boxSpan.style.height = '10px'
      boxSpan.style.marginRight = '10px'
      boxSpan.style.width = '30px'

      // Text
      const textContainer = document.createElement('p')
      textContainer.style.color = item?.fontColor
      textContainer.style.fontSize = '12px'
      textContainer.style.lineHeight = '12px'
      textContainer.style.margin = '0px'
      textContainer.style.padding = '0px'
      textContainer.style.textDecoration = item?.hidden ? 'line-through' : ''
      const text = document.createTextNode(item?.text)
      textContainer.appendChild(text)

      li.appendChild(boxSpan)
      li.appendChild(textContainer)
      ul.appendChild(li)
    })
  }
}

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July']

export const data = {
  labels,
  datasets: [
    {
      label: 'tank',
      data: [0, 0, 0, 0, 0, 0, 0] || [1, 20, -3, 4, 54, 6, 7.7],
      borderColor: 'rgb(85, 161, 229)',
      backgroundColor: 'rgba(85, 161, 229, 0.5)',
      borderWidth: '2'
    },
    {
      label: 'mercedes-benz',
      data: [0, 0, 0, 0, 0, 0, 0] || [-15, 23, 3, 45, 5.5, 64, 7.3],
      borderColor: 'rgb(109, 189, 191)',
      backgroundColor: 'rgba(109, 189, 191, 0.5)',
      borderWidth: '2'
    },
    {
      label: 'haval',
      data: [0, 0, 0, 0, 0, 0, 0] || [15, -23, -30, -45, -5.5, -64, -7.3],
      borderColor: 'rgb(237, 110, 133)',
      backgroundColor: 'rgba(237, 110, 133, 0.5)',
      borderWidth: '2'
    }
  ]
}
