import styled from '@emotion/styled/macro'

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LoaderInner = styled.div`
  display: flex;
  width: 50px;
  height: 50px;
  border: 2px dashed grey;
  transition: all 0.5s ease;
  transform: rotate(0deg);
  animation: move 3s infinite linear;
  border-radius: 50%;

  @keyframes move {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
