import styled from '@emotion/styled/macro'

export const InputInner = styled.input<{ padding: boolean }>`
  height: 2.8rem;
  padding: ${({ padding }) => `0 ${padding ? '3rem' : '0'} 0 0`};
  border: none;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
`

export const InputWrap = styled.div`
  display: flex;
  position: relative;
  width: 100%;

  img {
    position: absolute;
    top: calc(50% - 1.7rem);
    right: 0;
    cursor: pointer;
  }
`
