import { instance, instanceBilling } from './index'

export function downloadBillingAnalyticsFile({
  billingId,
  format,
  params
}: {
  billingId: string | null
  format: string
  params: URLSearchParams | null
}) {
  return instance
    .get(`/analytics/${billingId}/daily/download/${format}${params ? `?${params}` : ''}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      throw new Error(`HTTP error! Status: ${error.response.status}`)
    })
}

export function downloadDocumentFile({ id, file_id, report_id }: { id: number; file_id: number; report_id?: number }) {
  return instanceBilling
    .get(`/client/${id}/docs/file/${file_id}${report_id ? `?report_id=${report_id}` : ''}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
      // throw new Error(`HTTP error! Status: ${error}`)
    })
}
