import { FC } from 'react'

import { LoaderContainer, LoaderInner } from './style'

export const Loader: FC = () => {
  return (
    <LoaderContainer>
      <LoaderInner />
    </LoaderContainer>
  )
}
