import { createApi } from '@reduxjs/toolkit/query/react'

import { ClientUsersByIdResponseType } from '../@types/billingUserTypes'
import {
  BillingAnalyticsDailyByIdResponseType,
  BillingAnalyticsDailyByIdType,
  CriticalUnreadNotificationsResponseType,
  MarkNotificationAsReadRequestType,
  UserChangePasswordRequestType,
  UserChangePasswordResponseType,
  UserConfirmEmailRequestType,
  UserConfirmEmailResponseType,
  UserDataType,
  UserLoginRequestType,
  UserLoginResponseType,
  UserNotification,
  UserRegistrationRequestType,
  UserResetPasswordRequestType,
  UserShowcaseAutoInfo
} from '../@types/userTypes'
import { customFetchBaseWithReauth } from '.'

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: (args, api, extraOptions) =>
    customFetchBaseWithReauth({ ...args, baseUrl: process.env.REACT_APP_API_URL }, api, extraOptions),
  endpoints: (builder) => ({
    registerUser: builder.mutation<UserDataType, UserRegistrationRequestType>({
      query: ({ ...rest }) => {
        const lowerCaseEmail = rest?.data?.attributes?.email?.toLowerCase()
        rest.data.attributes.email = lowerCaseEmail
        return {
          url: '/register',
          method: 'POST',
          body: rest
        }
      },
      transformResponse: (response: { data: UserDataType }, meta, arg) => response?.data
    }),
    checkEmailCode: builder.mutation<UserConfirmEmailResponseType, UserConfirmEmailRequestType>({
      query: ({ ...rest }) => ({
        url: '/confirm_email',
        method: 'POST',
        body: rest
      }),
      transformResponse: (response: { data: UserConfirmEmailResponseType }, meta, arg) => response?.data
    }),
    changeUserPassword: builder.mutation<UserChangePasswordResponseType, UserChangePasswordRequestType>({
      query: ({ ...rest }) => ({
        url: '/change_password',
        method: 'POST',
        body: rest
      }),
      transformResponse: (response: { data: UserChangePasswordResponseType }, meta, arg) => response?.data
    }),
    userLogin: builder.mutation<UserLoginResponseType, UserLoginRequestType>({
      // providesTags: undefined,
      // invalidatesTags: undefined,
      // extraOptions: undefined,
      // transformErrorResponse: undefined,
      // onQueryStarted: undefined,
      // queryFn: undefined,
      // structuralSharing: false,
      query: ({ ...rest }) => {
        const lowerCaseEmail = rest?.data?.attributes?.email?.toLowerCase()
        rest.data.attributes.email = lowerCaseEmail
        return {
          url: '/login',
          method: 'POST',
          body: rest
        }
      },
      transformResponse: (response: { data: UserLoginResponseType }, meta, arg) => {
        return response?.data
      }
    }),
    userLogout: builder.mutation<any, {}>({
      query: () => ({
        url: '/logout',
        method: 'POST'
      }),
      transformResponse: (response: { data: UserDataType }, meta, arg) => response?.data
    }),
    resetUserPassword: builder.mutation<{}, UserResetPasswordRequestType>({
      query: ({ ...rest }) => ({
        url: '/reset_password',
        method: 'POST',
        body: rest
      })
    }),
    getUserData: builder.mutation<UserDataType, {}>({
      query: () => ({
        url: '/user_data',
        method: 'GET'
      }),
      transformResponse: (response: { data: UserDataType }, meta, arg) => response?.data
    }),
    updateUserData: builder.mutation<UserDataType, {}>({
      query: ({ body, auth_token }: { body: any; auth_token: string }) => ({
        url: '/user_data',
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${auth_token}`
        },
        body
      }),
      transformResponse: (response: { data: UserDataType }, meta, arg) => response?.data
    }),
    completeRegistration: builder.mutation({
      query: ({ body }: { body: any }) => ({
        url: '/complete_registration',
        method: 'POST',
        body
      })
    }),
    getShowcaseAutoInfo: builder.mutation<UserShowcaseAutoInfo, { id: string }>({
      query: ({ id }) => ({
        url: `/oneplatform/avn/${id}`,
        method: 'GET'
      })
    }),
    getBillingAnalyticsDailyById: builder.mutation<BillingAnalyticsDailyByIdType, { id: string; userId: string }>({
      query: ({ id, userId }) => ({
        url: `/analytics/${id}/daily`,
        method: 'GET'
        // headers: {
        //   UserId: userId
        // }
      }),
      transformResponse: (response: BillingAnalyticsDailyByIdResponseType, meta, arg) => response?.data
    }),
    getBillingAllAnalyticsDailyById: builder.mutation<
      BillingAnalyticsDailyByIdType,
      { id: string; userId: string; params?: URLSearchParams }
    >({
      query: ({ id, userId, params }) => ({
        url: `/analytics/${id}/daily_full${params ? `?${params}` : ''}`,
        method: 'GET'
      }),
      transformResponse: (response: BillingAnalyticsDailyByIdResponseType, meta, arg) => response?.data
    }),
    getCriticalUnreadNotifications: builder.mutation<CriticalUnreadNotificationsResponseType, void>({
      query: () => ({
        url: '/user_notifications/critical/unread',
        method: 'GET'
      }),
      transformResponse: (response: { data: UserNotification[] }) => response
    }),
    markNotificationAsRead: builder.mutation<void, MarkNotificationAsReadRequestType>({
      query: ({ notificationId }) => ({
        url: `/user_notifications/${notificationId}/read`,
        method: 'PATCH'
      })
    }),
    getAllUserNotifications: builder.mutation<any, { size?: number; num?: number }>({
      query: ({ size, num }) => ({
        url: `/user_notifications?page[size]=${size}&page[number]=${num}`,
        method: 'GET'
      }),
      transformResponse: (response: { data: UserNotification[] }) => response
    }),
    markAllNotCriticalNotificationAsRead: builder.mutation<void, void>({
      query: () => ({
        url: `/user_notifications/not_critical/read_all`,
        method: 'PATCH'
      })
    }),
    getUnreadNotifications: builder.mutation<CriticalUnreadNotificationsResponseType, void>({
      query: () => ({
        url: '/user_notifications/unread_count',
        method: 'GET'
      }),
      transformResponse: (response: { data: UserNotification[] }) => response
    }),
    getExtendedUsersByClientId: builder.mutation<ClientUsersByIdResponseType, { id: string }>({
      query: ({ id }) => ({
        url: `/users_extended/by_client_id/${id}`,
        method: 'GET'
      })
    }),
    getSitesInfoByClient: builder.mutation<any, { id: string }>({
      query: (clientId) => ({
        url: `/clients/${clientId}/sites_info`,
        method: 'GET'
      })
    }),
    getPagesLinksBySiteID: builder.mutation<any, { clientId: string; siteId: string }>({
      query: ({ clientId, siteId }) => ({
        url: `/clients/${clientId}/sites/${siteId}/pages_links`,
        method: 'GET'
      })
    }),
    getPageClicks: builder.mutation<
      any,
      { clientId: string; siteId: string; pageId: string; sw: number; dateFrom: string; dateTo: string }
    >({
      query: ({ clientId, siteId, pageId, sw, dateFrom, dateTo }) => ({
        url: `/clients/${clientId}/sites/${siteId}/page_clicks/${pageId}?sw=${sw}&date_from=${dateFrom}&date_to=${dateTo}`,
        method: 'GET'
      })
    })
  })
})

export const {
  useRegisterUserMutation,
  useCheckEmailCodeMutation,
  useChangeUserPasswordMutation,
  useUserLoginMutation,
  useResetUserPasswordMutation,
  useGetUserDataMutation,
  useUpdateUserDataMutation,
  useGetShowcaseAutoInfoMutation,
  useGetBillingAllAnalyticsDailyByIdMutation,
  useGetBillingAnalyticsDailyByIdMutation,
  useGetCriticalUnreadNotificationsMutation,
  useMarkNotificationAsReadMutation,
  useCompleteRegistrationMutation,
  useGetAllUserNotificationsMutation,
  useMarkAllNotCriticalNotificationAsReadMutation,
  useGetUnreadNotificationsMutation,
  useUserLogoutMutation,
  useGetExtendedUsersByClientIdMutation,
  useGetSitesInfoByClientMutation,
  useGetPagesLinksBySiteIDMutation,
  useGetPageClicksMutation
} = userApi
