import { createSlice } from '@reduxjs/toolkit'

import { EditAddEmployee } from '../@types/editAddEmployee'

const initialState = {
  isNewEmployee: true,
  attributes: {
    firstName: '',
    lastName: '',
    position: '',
    company: '',
    phone: '',
    email: ''
  },
  listEmployees: [
    {
      firstName: 'Михаил',
      lastName: 'Смирнов',
      email: 'romanpro@yamdex.ru',
      date: '17.05.2023',
      owner: true
    },
    {
      firstName: 'Александра',
      lastName: 'Кожевникова',
      email: 'kozhevnikovaalexandra@yandex.ru',
      date: '7.05.2023',
      owner: false
    },
    {
      firstName: 'Виниамина',
      lastName: 'Христорождественская',
      email: 'vikhristo@yandex.ru',
      date: '7.05.2023',
      owner: false
    },
    {
      firstName: 'Константин',
      lastName: 'Константиновский',
      email: 'vikhristo@yandex.ru',
      date: '25.01.2023',
      owner: false
    },
    {
      firstName: 'Олег',
      lastName: 'Иванов',
      email: 'vikhristo@yandex.ru',
      date: '3.01.2023',
      owner: false
    },
    {
      firstName: 'Ольга',
      lastName: 'Захарова',
      email: 'vikhristo@yandex.ru',
      date: '31.01.2023',
      owner: false
    }
  ]
}

const editAddEmployeeSlice = createSlice({
  name: 'editAddEmployee',
  initialState,
  reducers: {
    setIsNewEmployee: (state, { payload }) => {
      state.isNewEmployee = payload
    },
    setDataForForm: (state, { payload }) => {
      state.attributes = payload
    },
    addEmployee: (state, { payload }) => {
      state.listEmployees.push(payload)
      return
    }
  }
})

export const { setIsNewEmployee, setDataForForm, addEmployee } = editAddEmployeeSlice.actions
export const selectEditAddEmployee = (state: { employee: EditAddEmployee }) => state.employee
export default editAddEmployeeSlice.reducer
