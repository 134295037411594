export const ONE_PLATFORM = 'Платформа сайтов'
export const SHOWCASE_AUTO = 'Витрина авто'
export const SMART_CONTRACT = 'Цифровая сделка'
export const TELEGRAM_SHOWCASE = 'Телеграм витрина'
export const CRM_DEALER = 'CRM Автодилер - Инфотек'
export const DIGITAL_ADVERTISING = 'Поисковая оптимизация и цифровая реклама'
export const ANALYTICS = 'Сквозная аналитика'
export const LMS = 'Система онлайн обучения'
export const KMS = 'Система управления знаниями'
export const PROJECT_IN_DEVELOPMENT = 'Проект в разработке'
