import { createSlice } from '@reduxjs/toolkit'

import { EditAddEmployee } from '../@types/editAddEmployee'

const initialState = {
  currentProduct: {
    client_id: 0,
    product_name: ''
  }
}

const currentProductSlice = createSlice({
  name: 'currentProduct',
  initialState,
  reducers: {
    setCurrentProduct: (state, { payload }) => {
      state.currentProduct = payload
    }
  }
})

export const { setCurrentProduct } = currentProductSlice.actions
export const selectCurrentProduct = (state: { curProduct: any }) => state.curProduct
export default currentProductSlice.reducer
