import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit'
import { isRejectedWithValue } from '@reduxjs/toolkit'
import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'
import axios, { AxiosRequestConfig } from 'axios'

import { UserRefreshResponseType } from '../@types/userTypes'
import { BACKEND_CODES } from '../constants/backendCodes'
import { ROUTES } from '../constants/routes'
import { setNotificationAlert } from '../store/notificationsSlise'
import { deleteUser } from '../store/userSlice'
import { getLocalStorageValue, removeLocalStorageValue, setLocalStorageValue } from '../utils/localStorageHelpers'
// TODO: настройка axios, можно делать запросы импортируя instance, если не нужен все-таки, удаляем

export const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/json'
  }
})

export const instanceBilling = axios.create({
  baseURL: `${process.env.REACT_APP_BILLING_API_URL}`,
  headers: {
    'Content-Type': 'application/json'
  }
})

async function refreshToken(instance: any) {
  const refresh_token = getLocalStorageValue('user_tokens')?.refresh_token || ''
  // const bearer = `Bearer ${refresh_token}`

  const response = await instance.post(`${process.env.REACT_APP_API_URL}/refresh`, {
    data: {
      attributes: {
        refresh_token: refresh_token
      }
    }
  })
  return response.data?.data?.attributes?.auth_token
}

const setToken = (config: any) => {
  const token = getLocalStorageValue('user_tokens')?.auth_token
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }
  return config
}

instance.interceptors.request.use(setToken)

instanceBilling.interceptors.request.use(setToken)

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return errorRequest(error, instance)
  }
)

instanceBilling.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return errorRequest(error, instanceBilling)
  }
)

const errorRequest = async (error: any, instanceVal: any) => {
  const originalRequest = error.config
  const newInstanse = instanceVal
  // Если статус ошибки 403 и ранее не было попыток повторного запроса
  if (error.response.status === 403 && !originalRequest._retry) {
    originalRequest._retry = true
    const newToken = await refreshToken(newInstanse)
    // Если токен успешно обновлен
    if (newToken) {
      setLocalStorageValue('user_tokens', {
        ...getLocalStorageValue('user_tokens'),
        auth_token: newToken
      })
      originalRequest.headers['Authorization'] = `Bearer ${newToken}`
      return newInstanse(originalRequest) // Повторный запрос
    } else {
      // Если обновление токена не удалось, удаляем токены и перенаправляем пользователя на страницу входа
      removeLocalStorageValue('user_tokens')
      removeLocalStorageValue('user')
      window.location.href = ROUTES?.login
    }
  } else if (
    originalRequest._retry &&
    error.response.data?.errors?.some((e: { code: string }) => e.code === 'internal_error')
  ) {
    // Если это повторный запрос и произошла внутренняя ошибка, то останавливаем дальнейшие попытки
    return Promise.reject('Token refresh failed')
  }
  return Promise.reject(error)
}

export const rtkQueryErrorCatcher: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    // NOTE: Функция служит для отлова ошибок экшенов, здесь можно вызывать попапы с ошибками например
  }

  return next(action)
}

const mutex = new Mutex()

const baseQuery: BaseQueryFn<string | FetchArgs | any, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const rawBaseQuery = fetchBaseQuery({
    baseUrl: args?.baseUrl,
    prepareHeaders: (headers: any) => {
      const auth_token = getLocalStorageValue('user_tokens')?.auth_token || ''

      //@ts-ignore
      if (auth_token && !headers.has('Authorization') && !extraOptions?.isRefresh) {
        headers.set('Authorization', `Bearer ${auth_token}`)
      }

      return headers
    }
  })
  return rawBaseQuery(args, api, extraOptions)
}

export const customFetchBaseWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock()

  let result = await baseQuery(args, api, extraOptions)

  // Проверяем ошибку после первого запроса
  // if (result.error && result.error?.status !== BACKEND_CODES?.unauthorized) {
  //   throw result.error
  // }

  if (result.error?.status === BACKEND_CODES?.accsess_token_expired) {
    api.dispatch(setNotificationAlert(true))
  }

  if (result.error?.status === BACKEND_CODES?.unauthorized) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()

      try {
        const refresh_token = getLocalStorageValue('user_tokens')?.refresh_token || ''
        const refreshResult = (await baseQuery(
          {
            url: `${process.env.REACT_APP_API_URL}/refresh`,
            method: 'POST',
            body: {
              data: {
                attributes: {
                  refresh_token: refresh_token
                }
              }
            }
          },
          api,
          { isRefresh: true }
        )) as UserRefreshResponseType

        if (refreshResult?.data?.data?.attributes?.auth_token) {
          setLocalStorageValue('user_tokens', {
            refresh_token: refreshResult?.data?.data?.attributes?.refresh_token,
            auth_token: refreshResult?.data?.data?.attributes?.auth_token
          })
          result = await baseQuery(args, api, extraOptions)

          // Проверяем ошибку после повторного запроса
          if (result.error) {
            throw result.error
          }
        } else {
          removeLocalStorageValue('user_tokens')
          removeLocalStorageValue('user')
          api.dispatch(deleteUser())
          window.location.href = ROUTES?.login
        }
      } finally {
        // release must be called once the mutex should be released again.
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)

      // Проверяем ошибку после повторного запроса (если был)
      if (result.error) {
        throw result.error
      }
    }
  }

  return result
}
