import styled from '@emotion/styled/macro'

export const Container = styled.div`
  width: 100%;
  background: #f7f8fa;
`

export const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  background: white;
  margin: 0 0 40px 0;
  border-radius: 8px;
  padding: 11px 30px;
  align-items: center;

  .analytic-tab {
    color: #808080;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    position: relative;
    text-decoration: none;

    &.active {
      color: #223361;
    }

    &.active::before {
      content: '';
      position: absolute;
      top: 27px;
      left: 15%;
      width: 120%;
      transform: translateX(-20%);
      border-radius: 8px;
      background: #223361;
      height: 2px;
    }
  }
`

export const NavItems = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`
