import { createApi } from '@reduxjs/toolkit/query/react'

import { DemoCabinetResponse } from '../@types/demoCabinetTypes'
import { UserDataType } from '../@types/userTypes'
import { customFetchBaseWithReauth } from '.'

export const getDemoCabinetUrlApi = createApi({
  reducerPath: 'getDemoCabinetUrlApi',
  baseQuery: (args, api, extraOptions) =>
    customFetchBaseWithReauth({ ...args, baseUrl: process.env.REACT_APP_API_URL }, api, extraOptions),
  endpoints: (builder) => ({
    getDemoCabinetUrl: builder.mutation<any, { url: string }>({
      query: ({ url }) => ({
        url: url,
        method: 'POST'
      }),
      transformResponse: (response: DemoCabinetResponse) => response?.data
    }),
    getUserOPData: builder.mutation<UserDataType, { url: string }>({
      query: ({ url }) => ({
        url: url,
        method: 'POST'
      }),
      transformResponse: (response: { data: UserDataType }, meta, arg) => response?.data
    })
  })
})

export const { useGetDemoCabinetUrlMutation, useGetUserOPDataMutation } = getDemoCabinetUrlApi
