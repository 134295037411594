import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import { rtkQueryErrorCatcher } from '../api'
import { billingUserApi } from '../api/billingUserApi'
import { getDemoCabinetUrlApi } from '../api/demoCabinetUrl'
import { documentsApi } from '../api/documentsApi'
import { getPresentationApi } from '../api/getPresentationApi'
import { historyOperationsApi } from '../api/historyOperationsApi'
import { tariffsApi } from '../api/tariffsApi'
import { trackerApi } from '../api/trackerApi'
import { userApi } from '../api/userApi'
import { userEventsApi } from '../api/userClickEventsApi'
import animationSlice from './animationSlice'
import currentProductSlice from './currentProductSlice'
import editAddEmployeeSlice from './editAddEmployeeSlice'
import loginSlice from './loginSlice'
import menuStatusSlice from './menuStatusSlice'
import notificationsSlice from './notificationsSlise'
import redirectSlice from './redirectSlice'
import registrationSlice from './registrationSlice'
import userBillingSlice from './userBillingSlice'
import userSlice from './userSlice'

const reducer = combineReducers({
  user: userSlice,
  userBilling: userBillingSlice,
  login: loginSlice,
  registration: registrationSlice,
  menuStatus: menuStatusSlice,
  animation: animationSlice,
  notifications: notificationsSlice,
  [userApi.reducerPath]: userApi.reducer,
  [historyOperationsApi.reducerPath]: historyOperationsApi.reducer,
  [billingUserApi.reducerPath]: billingUserApi.reducer,
  [trackerApi.reducerPath]: trackerApi.reducer,
  [getPresentationApi.reducerPath]: getPresentationApi.reducer,
  [getDemoCabinetUrlApi.reducerPath]: getDemoCabinetUrlApi.reducer,
  [documentsApi.reducerPath]: documentsApi.reducer,
  [userEventsApi.reducerPath]: userEventsApi.reducer,
  [tariffsApi.reducerPath]: tariffsApi.reducer,
  employee: editAddEmployeeSlice,
  curProduct: currentProductSlice,
  redirect: redirectSlice
})

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => [
    rtkQueryErrorCatcher,
    ...getDefaultMiddleware().concat([
      userApi.middleware,
      historyOperationsApi.middleware,
      trackerApi.middleware,
      billingUserApi.middleware,
      getPresentationApi.middleware,
      getDemoCabinetUrlApi.middleware,
      documentsApi.middleware,
      userEventsApi.middleware,
      tariffsApi.middleware
    ])
  ]
})

setupListeners(store.dispatch)

export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
