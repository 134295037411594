import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { BiRuble } from 'react-icons/bi'
import { useSelector } from 'react-redux'

import {
  useGetBillingClientByIdMutation,
  useGetBillingClientInfoByIdMutation,
  usePostInvoicingMutation
} from '../../api/billingUserApi'
import { useSendUserClickEventMutation } from '../../api/userClickEventsApi'
import fileCheck from '../../assets/icons/fileCheck.svg'
import { ReactComponent as IInCircle } from '../../assets/icons/iInCircle.svg'
import pencil from '../../assets/icons/pencil.svg'
import { Container, GreenText, RedText } from '../../assets/styles/global'
import { EditAddEmployee } from '../../components/form/EditAddEmployee'
import { EditAddress } from '../../components/form/EditAddress'
import { EditPersonalInfo } from '../../components/form/EditPersonalInfo'
import { Invoicing } from '../../components/invoicing/Invoicing'
import { ROUTES } from '../../constants/routes'
import { selectEditAddEmployee } from '../../store/editAddEmployeeSlice'
import { selectUserBilling } from '../../store/userBillingSlice'
import { Button } from '../../ui/button'
import { Image } from '../../ui/image'
import { Popup } from '../../ui/popup/Popup'
import { ResponsePopup } from '../../ui/popup/responsePopup/ResponsePopup'
import { getLocalStorageValue } from '../../utils/localStorageHelpers'
import { numberWithSpaces } from '../../utils/numberWithSpaces'
import { HeaderSettings } from './headerSettings/HeaderSettings'
import { HeaderTariffs } from './headerTariffs/HeaderTariffs'
import { ListEmployee } from './listEmployee/ListEmployee'
import {
  Balance,
  Block,
  Field,
  FieldInner,
  Notification,
  NotificationListClients,
  SubTitle,
  Title,
  TitlePopup,
  WrapButton
} from './style'

const componentByValue = {
  positive: GreenText,
  negative: RedText
}

export const Settings = () => {
  const [isShowPopupEditPersonalInfo, setIsShowPopupEditPersonalInfo] = useState<boolean>(false)
  const [isShowPopupEditAddress, setIsShowPopupEditAddress] = useState<boolean>(false)
  const [isShowPopupEditAddEmployee, setIsShowPopupEditAddEmployee] = useState<boolean>(false)
  const [billingClientData, setBillingClientData] = useState<any>({})
  const [isInvoicing, setIsInvoicing] = useState<boolean>(false)
  const { isNewEmployee } = useSelector(selectEditAddEmployee)
  const [isShowInvoicingSuccess, setIsShowInvoicingSuccess] = useState<boolean>(false)
  const [sendUserClickEvent] = useSendUserClickEventMutation()
  const { listClients, testAB } = useSelector(selectUserBilling)
  const [editAddressData, setEditAddressData] = useState<any>({})

  const userType = getLocalStorageValue('user')?.attributes?.type

  const { billingSelectedDealerId, billingSelectedDealer, billingClientInfo } = useSelector(selectUserBilling)
  const [
    getBillingClientInfoById,
    {
      isLoading: isLoadingBillingClientInfoById,
      data: billingClientInfoById,
      isError: isErrorBillingClientInfoById,
      error: billingClientInfoByIdError,
      isSuccess: isSuccessBillingClientInfoById
    }
  ] = useGetBillingClientInfoByIdMutation()

  const [
    getBillingClientById,
    {
      isLoading: isLoadingBillingUserById,
      data: billingClientById,
      isError: isErrorBillingUserById,
      error: billingUserByIdError,
      isSuccess: isSuccessBillingUserById
    }
  ] = useGetBillingClientByIdMutation()

  const [
    postInvoicing,
    {
      isLoading: invoicingIsLoading,
      data: invoicingData,
      isError: invoicingIsError,
      error: invoicingError,
      isSuccess: invoicingIsSuccess
    }
  ] = usePostInvoicingMutation()

  useEffect(() => {
    ;(async () => {
      if (billingSelectedDealerId) {
        await getBillingClientInfoById({ id: billingSelectedDealerId })
        await getBillingClientById({ id: billingSelectedDealerId })
      }
    })()
  }, [billingSelectedDealerId])

  useEffect(() => {
    setBillingClientData(billingClientInfoById)
  }, [billingClientInfoById])

  useEffect(() => {
    setEditAddressData({
      ...billingClientById?.billing_address,
      email: billingClientById?.email
    })
  }, [billingClientById])

  const PriceBox = componentByValue[Number(billingClientInfoById?.balance?.total) > 0 ? 'positive' : 'negative']

  return (
    <Container>
      {(!listClients || !listClients.length || !billingClientInfoById) && ( //Если список клиентов не пришел то показываем это сообщение
        <NotificationListClients>
          <IInCircle />В вашем личном кабинете нет закрепленного лицевого счета. Обратитесь к вашему Аккаунт менеджеру
          для его получения.
        </NotificationListClients>
      )}
      {isShowPopupEditPersonalInfo && (
        <Popup closePopup={() => setIsShowPopupEditPersonalInfo(false)}>
          <TitlePopup>Личные данные</TitlePopup>
          <EditPersonalInfo closePopup={() => setIsShowPopupEditPersonalInfo(false)} />
        </Popup>
      )}
      {isShowPopupEditAddress && (
        <Popup closePopup={() => setIsShowPopupEditAddress(false)}>
          <TitlePopup>Адрес доставки</TitlePopup>
          <EditAddress
            id={billingClientById?.id}
            formData={editAddressData}
            onClose={async () => {
              await getBillingClientById({ id: billingClientById?.id.toString() || '' })
              setIsShowPopupEditAddress(false)
            }}
            isEdo={Boolean(billingClientById?.is_edo)}
          />
        </Popup>
      )}
      {isShowPopupEditAddEmployee && (
        <Popup closePopup={setIsShowPopupEditAddEmployee}>
          <TitlePopup>{isNewEmployee ? 'Добавить сотрудника' : 'Редактировать сотрудника'}</TitlePopup>
          <EditAddEmployee closePopup={setIsShowPopupEditAddEmployee} />
        </Popup>
      )}
      {isInvoicing && (
        <Popup closePopup={setIsInvoicing} background={true}>
          <Invoicing
            billingClientInfoById={billingClientInfoById}
            postInvoicing={postInvoicing}
            invoicingIsSuccess={invoicingIsSuccess}
            invoicingIsError={invoicingIsError}
            setIsInvoicingSuccess={setIsShowInvoicingSuccess}
            setIsInvoicing={setIsInvoicing}
          />
        </Popup>
      )}
      {/*Success Модалка выставления счета*/}
      {isShowInvoicingSuccess && (
        <Popup closePopup={setIsShowInvoicingSuccess} background={true}>
          <ResponsePopup
            setIsShowPopup={setIsShowInvoicingSuccess}
            status={invoicingIsError ? 'error' : 'success'}
            successText={`Счет на оплату успешно создан! Счет отправлен на эл. адрес ${billingClientById?.email}`}
            errorText={'Ошибка создания счета!'}
          />
        </Popup>
      )}

      <HeaderSettings openModal={setIsShowPopupEditPersonalInfo} />
      {testAB === '2' && billingSelectedDealer ? <HeaderTariffs /> : null}
      <Field>
        {listClients && !!listClients.length && billingClientInfoById && (
          <>
            <FieldInner>
              <Title>
                Адрес доставки
                <Image src={pencil} alt="pencil" onClick={() => setIsShowPopupEditAddress(true)} />
              </Title>
              <Notification isGreenText={billingClientById?.is_edo}>
                <Image src={fileCheck} alt="fileCheck" />
                {billingClientById?.is_edo
                  ? 'Электронные версии документов направляются через ЭДО'
                  : 'На этот адрес будут отправлены оригиналы документов'}
              </Notification>
              <Block>
                <SubTitle>Название организации</SubTitle>
                <div>{billingClientById?.billing_address?.name || '-'}</div>
              </Block>
              <Block>
                <Block>
                  <SubTitle>Получатель</SubTitle>
                  <div>{billingClientById?.billing_address?.receiver || '-'}</div>
                </Block>
              </Block>
              <Block>
                <SubTitle>Адрес</SubTitle>
                <div>{billingClientById?.billing_address?.address || '-'}</div>
              </Block>
              {billingClientById?.is_forward_client && (
                <Block>
                  <SubTitle>E-mail для уведомлений и документов</SubTitle>
                  <div>{billingClientById?.email || '-'}</div>
                </Block>
              )}
            </FieldInner>
            <FieldInner>
              <Title>
                Баланс
                <Balance>
                  <PriceBox>
                    {billingClientInfoById?.balance?.total
                      ? numberWithSpaces(parseFloat(billingClientInfoById?.balance?.total)?.toFixed(2))
                      : '−'}
                    <BiRuble />
                  </PriceBox>
                </Balance>
              </Title>
              <Notification isRed={Number(billingClientInfoById?.balance?.total) <= 0}>
                <IInCircle />
                {Number(billingClientInfoById?.balance?.total) <= 0
                  ? 'Рекомендуем пополнить лицевой счет, чтобы избежать отключения модулей'
                  : 'Рекомендуем пополнить лицевой счет, чтобы избежать отключения модулей в будущем'}
              </Notification>
              <Block>
                <SubTitle>№ {billingClientInfoById?.wallet?.number || '-'}</SubTitle>
                <div>Номер лицевого счета</div>
              </Block>
              <Block>
                <SubTitle>
                  {billingClientInfoById?.balance?.payment
                    ? numberWithSpaces(parseFloat(billingClientInfoById?.balance?.payment)?.toFixed(2))
                    : '-'}{' '}
                  <BiRuble />
                </SubTitle>
                <div>
                  {!billingClientInfoById ? (
                    '-'
                  ) : billingClientInfoById?.balance?.paid ? (
                    `Следующий платеж без учета НДС
                (${
                  (billingClientInfoById?.balance?.next_payment_date &&
                    format(new Date(billingClientInfoById?.balance?.next_payment_date), 'dd.MM.yy')) ||
                  '-'
                })`
                  ) : !(Number(billingClientInfoById?.balance?.payment) > 0) &&
                    !billingClientInfoById?.balance?.paid ? (
                    'Оплата не требуется'
                  ) : (
                    <RedText>Не оплачено</RedText>
                  )}
                </div>
              </Block>
              <Block>
                <SubTitle>
                  {billingClientInfoById?.balance?.recommend_payment
                    ? numberWithSpaces(Number(billingClientInfoById.balance.recommend_payment).toFixed(2))
                    : '−'}
                  <BiRuble />
                </SubTitle>
                <div>Рекомендуемая сумма пополнения</div>
              </Block>
              <WrapButton>
                <Button
                  onClick={() => {
                    setIsInvoicing(true)
                    if (userType !== 'internal') {
                      sendUserClickEvent('button_top_up_account_click')
                    }
                  }}
                  size={'medium'}
                >
                  Пополнить счет
                </Button>
                <a
                  href={
                    billingClientInfoById?.contract?.file
                      ? `${process.env.REACT_APP_BILLING_API_URL}${ROUTES?.billingContractFileById}/${billingClientInfoById?.contract?.file}`
                      : './dogovorsign.pdf'
                  }
                  target="_blank"
                  download
                  rel="noreferrer"
                >
                  Договор обслуживания
                </a>
              </WrapButton>
            </FieldInner>
          </>
        )}
      </Field>
      {/* <Button
        icon={plus}
        size={'medium'}
        onClick={() => {
          setIsShowPopupEditAddEmployee(true)
          setIsNewEmployee(true)
          dispatch(setDataForForm({}))
        }}
      >
        Добавить сотрудника
      </Button> */}
      <ListEmployee openModal={setIsShowPopupEditAddEmployee} />
    </Container>
  )
}
