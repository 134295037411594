import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import styled from '@emotion/styled/macro'

export const ContainerProduct = styled.div`
  position: relative;
`

export const Title = styled.div`
  ${({ theme }) => theme.title.h1};
  color: ${({ theme }) => theme.colors.accent};
  padding-bottom: 3.5rem;
  margin-top: 30px;
`

export const ProductWrapper = styled.div`
  .slick-slider {
    @media ${({ theme }) => theme.media.large} {
      width: calc(100% - 5rem);
    }
  }
  .slick-slide > div > div {
    width: 100%;
    justify-content: space-between;
    display: flex !important;
    gap: 2.4rem 2.4rem;

    @media ${({ theme }) => theme.media.extraLarge} {
      flex-direction: column;
    }
  }

  .slick-prev:before,
  .slick-next:before {
    content: none;
  }
`

export const Slide = styled.div`
  display: flex;
  gap: 2.4rem;

  @media screen and (max-width: 1440px) {
    flex-direction: column;
  }

  @media screen and (max-width: 1024px) {
    gap: unset;
  }
`

export const ProductContainer = styled.div``

export const LicenseMore = styled.button`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: #223361;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  margin-top: 32px;
  background: transparent;
  border: none;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`

export const MainInfoContainer = styled.div`
  display: flex;
  gap: 2.4rem;
  margin-top: 2.4rem;

  @media (max-width: 1220px) {
    flex-direction: column;
  }
`

export const MainInfoBlock = styled.div`
  width: 50%;
  gap: 2.4rem;
  display: flex;
  flex-direction: column;

  &:first-child {
    @media (max-width: 1320px) {
      width: 65%;
    }

    @media (max-width: 1220px) {
      width: 100%;
    }
  }

  &:last-child {
    @media (max-width: 1320px) {
      width: 35%;
    }

    @media (max-width: 1220px) {
      width: 100%;
    }
  }
`

export const MainInfoCard = styled.div`
  padding: 2.5rem 1.6rem 1.7rem;
  background-color: #ffffff;
  border-radius: 8px;
`

export const MainInfoTitle = styled.p`
  font-size: 24px;
  line-height: 33px;
  color: #1b2559;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`
