import React, { useEffect, useState } from 'react'

import close from '../../assets/icons/close.svg'
import lms from '../../assets/icons/lms.svg'
import { Image } from '../../ui/image'
import {
  ButtonContainer,
  CloseButton,
  NotificationContent,
  NotificationImage,
  NotificationInner,
  NotificationWindow,
  PulsingButton,
  Ripple,
  SubTitle,
  Title
} from './style'

export const ChatWidget = () => {
  const [isNotificationVisible, setNotificationVisibility] = useState(true)

  const handleChatClick = () => {
    window.open('https://academy.udpauto.ru/')
  }

  useEffect(() => {
    const storedVisibility = localStorage.getItem('notificationVisibility')
    if (storedVisibility !== null) {
      setNotificationVisibility(storedVisibility === 'true')
    }
  }, [])

  const handleCloseNotification = () => {
    setNotificationVisibility(false)
    localStorage.setItem('notificationVisibility', 'false')
  }

  return (
    <>
      <NotificationWindow isVisible={isNotificationVisible}>
        <CloseButton onClick={handleCloseNotification}>
          <Image src={close} alt="close" />
        </CloseButton>
        <NotificationInner>
          <NotificationImage />
          <NotificationContent>
            <Title>UDP Auto</Title>
            <SubTitle>
              Здравствуйте! Возникли вопросы по работе с личным кабинетом? Найдите быстрый ответ в нашей базе знаний.
            </SubTitle>
          </NotificationContent>
        </NotificationInner>
      </NotificationWindow>
      <ButtonContainer onClick={handleChatClick}>
        <Ripple />
        <PulsingButton>
          <Image src={lms} alt="lms" />
        </PulsingButton>
      </ButtonContainer>
    </>
  )
}
