import styled from '@emotion/styled/macro'

export const Title = styled.div<{ isDevelop: boolean }>`
  ${({ theme }) => theme.title.h2};
  color: ${({ theme, isDevelop }) => (isDevelop ? 'transparent' : theme.colors.accent)};
  font-family: 'Helvetica', sans-serif;
  display: flex;
  gap: 1.6rem;
  justify-content: space-between;
  font-weight: 400;

  img {
    cursor: pointer;
  }
`

export const ClientPackagesWrapper = styled.div`
  padding-top: 20px;
`
