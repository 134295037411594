import { getNoun } from './getNoun'

const daysInMonth = (month: any) => {
  const today = new Date()
  return 33 - new Date(today.getFullYear(), month, 33).getDate()
}

export const numberDaysBeforeShutdown = () => {
  let days = { forms_disabled: 14, site_disabled: 28, site_deleted: 56 }
  const today = new Date()
  const firstDayCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1)
  const fifteenthDayCurrentMonth = new Date(firstDayCurrentMonth.getTime() - 1 + 15 * 24 * 60 * 60 * 1000)
  const lastDayOfTheMonth = new Date(
    firstDayCurrentMonth.getTime() + (daysInMonth(today.getMonth()) - 1) * 24 * 60 * 60 * 1000
  )

  if (today.getDate() < fifteenthDayCurrentMonth.getDate()) {
    days.forms_disabled = fifteenthDayCurrentMonth.getDate() - today.getDate()
  }

  // if (today.getDate() < lastDayOfTheMonth.getDate()) {
  //   days.site_disabled = lastDayOfTheMonth.getDate() - today.getDate()
  // }

  // if (today.getDate() < lastDayOfTheMonth.getDate() + daysInMonth(today.getMonth() + 1)) {
  //   days.site_deleted = lastDayOfTheMonth.getDate() + daysInMonth(today.getMonth() + 1) - today.getDate()
  // }

  return days
}
