import React, { DetailedHTMLProps, FC, InputHTMLAttributes, useState } from 'react'

import { InputProps } from '../../@types/formsUI'
import eye from '../../assets/icons/eye.svg'
import eye_crossed from '../../assets/icons/eye_crossed.svg'
import { Image } from '../image'
import { InputInner, InputWrap } from './style'

export const Input: FC<InputProps & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>> = ({
  type = 'text',
  register = null,
  name = '',
  required = false,
  error = null,
  maxLength = 100,
  ...rest
}) => {
  const [typeLocal, setTypeLocal] = useState(type)

  const changeVisible = () => {
    setTypeLocal(typeLocal === 'text' ? 'password' : 'text')
  }

  return (
    <InputWrap>
      <InputInner
        type={typeLocal}
        autocomplete="new-password"
        maxLength={maxLength}
        padding={type === 'password'}
        {...register(name, { required })}
        {...rest}
      />
      {eye && type === 'password' && (
        <Image src={typeLocal === 'text' ? eye_crossed : eye} alt="show/hide" onClick={changeVisible} />
      )}
    </InputWrap>
  )
}
