import { keyframes } from '@emotion/react'
import styled from '@emotion/styled/macro'

export const NotificationWindow = styled.div<{ isVisible: boolean }>`
  position: fixed;
  padding: 12px 20px 12px 14px;
  width: 312px;
  border: 2px solid #223361;
  background: #fff;
  border-radius: 15px;
  box-sizing: border-box;
  z-index: 1;
  cursor: pointer;
  right: 145px; // положение от правого края
  bottom: 53px; // положение от нижнего края
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};

  ::before,
  ::after {
    content: '';
    position: absolute;
    right: -10px; // Это значение зависит от толщины бордера и размера уголка.
    top: 70%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
  }

  ::before {
    border-left: 10px solid #223361; // Этот стиль создает внешний бордер уголка.
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }

  ::after {
    right: -8px; // Это значение на 2px больше, чем у ::before, чтобы создать "отступ" для бордера.
    border-left: 8px solid white; // Этот стиль создает внутренний, прозрачный уголок. Цвет должен соответствовать фону вашего NotificationWindow.
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
  }

  @media screen and (max-width: 483px) {
    display: none;
  }
`

export const CloseButton = styled.span`
  position: absolute;
  right: 4px;
  top: 4px;
  cursor: pointer;

  img {
    height: 20px;
    width: 20px;
  }
`

const ripple = keyframes`
  0% {
    width: 61px;  // Размер кнопки + обводка
    height: 61px;  // Размер кнопки + обводка
    opacity: 1;
  }
  100% {
    width: 112px;
    height: 112px;
    opacity: 0;
  }
`

// Стиль для контейнера, содержащего основную кнопку и обводку
export const ButtonContainer = styled.div`
  position: fixed;
  right: 69px;
  bottom: 53px;
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(34, 51, 97, 0.4);
  border-radius: 50%;

  @media screen and (max-width: 483px) {
    right: 16px;
    bottom: 16px;
  }
`

// Стиль для "кольца" (обводки), которое отходит от кнопки при "пульсации"
export const Ripple = styled.span`
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 61px;
  height: 61px;
  transform: translate(-50%, -50%);
  border: 2px solid rgba(34, 51, 97, 0.4); // Полупрозрачный синий цвет
  border-radius: 50%;
  animation: ${ripple} 1.5s infinite;
  z-index: 1;
`

// Стиль для основной кнопки
export const PulsingButton = styled.div`
  position: relative;
  display: flex;
  width: 53px;
  height: 53px;
  background: #223361;
  border-radius: 50%;
  cursor: pointer;
  overflow: visible;
  outline: none;
  z-index: 2;
  justify-content: center;
  align-items: center;

  img {
    width: 29px;
  }
`

export const NotificationInner = styled.div`
  display: flex;
  gap: 10px;
`

export const NotificationImage = styled.div`
  display: flex;
  min-width: 42px;
  height: 42px;
  background-image: url('https://corp.kodixauto.ru/upload/resize_cache/crm/306/100_100_1/4m2rvf0e0g6hud0w92v32r012y7qv08k.png');
  background-size: cover;
  border-radius: 100%;
`

export const NotificationContent = styled.div`
  p {
    margin-top: 4px;
  }
`

export const Title = styled.div`
  font: bold 14px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  color: #000;
`

export const SubTitle = styled.div`
  margin: 4px 0 0 0;
  font: 13px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  color: #424956;
`
