import { createApi } from '@reduxjs/toolkit/query/react'

import { customFetchBaseWithReauth } from '.'

export const getPresentationApi = createApi({
  reducerPath: 'getPresentationApi',
  baseQuery: (args, api, extraOptions) =>
    customFetchBaseWithReauth({ ...args, baseUrl: process.env.REACT_APP_API_URL }, api, extraOptions),
  endpoints: (builder) => ({
    getPresentation: builder.mutation<any, {}>({
      query: ({ ...rest }) => ({
        url: `/request_presentation`,
        method: 'POST',
        body: rest
      }),
      transformResponse: (response: { data: any }) => response?.data
    })
  })
})

export const { useGetPresentationMutation } = getPresentationApi
