import { createSlice } from '@reduxjs/toolkit'

import { MenuStatus } from '../@types/menuStatus'

const initialState = {
  isOpenMenu: false
}

const menuStatusSlice = createSlice({
  name: 'menuStatus',
  initialState,
  reducers: {
    toggleStatusMenu: (state, { payload }) => {
      state.isOpenMenu = payload
    }
  }
})

export const { toggleStatusMenu } = menuStatusSlice.actions

export const selectMenuStatus = (state: { menuStatus: MenuStatus }) => state.menuStatus

export default menuStatusSlice.reducer
