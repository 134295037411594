import React, { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react'

import fileCheck from '../../assets/icons/fileCheck.svg'
import plus from '../../assets/icons/plus.svg'
import { Image } from '../image'
import { StyledButton } from './style'

type ButtonProps = {
  background?: string
  borderRadius?: string
  icon?: string
  variant?: 'default' | 'secondary'
  positionIcon?: string
  size?: string
  className?: string
}
export const Button: FC<
  ButtonProps & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = ({ children, variant = 'default', icon, positionIcon = 'right', ...rest }) => {
  return (
    <StyledButton variant={variant} {...rest}>
      {icon && positionIcon === 'left' && <Image src={icon} alt="name" />}
      {children}
      {icon && positionIcon === 'right' && <Image src={icon} alt="name" />}
    </StyledButton>
  )
}
