import * as yup from 'yup'

export const invoicingSchema = yup.object().shape({
  sum: yup
    .string()
    .required('Введите сумму')
    .test('len', 'Можно ввести только целые числа или дробные', (val: any) => {
      const regex = /^[0-9]*[.]?[0-9]+$/
      return regex.test(val) && val > 0
    })
})
