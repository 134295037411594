import styled from '@emotion/styled/macro'

export const HeaderTariffsInner = styled.div`
  padding: 24px;
  background-color: #ffffff;
  margin-bottom: 24px;
  border-radius: 8px;
  position: relative;

  @media (max-width: 550px) {
    padding: 24px 16px;
  }
`

export const Title = styled.p`
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.44px;
  margin-bottom: 24px;

  @media (max-width: 550px) {
    margin-bottom: 16px;
    font-size: 20px;
  }
`

export const TariffsGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 550px) {
    grid-template-columns: 1fr;
    gap: 16px;
  }
`

export const TariffsEl = styled.div``

export const TarrifsTitle = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;

  @media (max-width: 550px) {
    margin-bottom: 8px;
  }
`

export const TarrifsSubtitle = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
  color: #666666;
`

export const Edit = styled.button`
  position: absolute;
  top: 50%;
  padding: 0;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  right: 24px;
  cursor: pointer;

  @media (max-width: 550px) {
    top: 24px;
    right: 16px;
    transform: none;
  }
`
