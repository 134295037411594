import { FC, ReactNode } from 'react'

import { Image } from '../image'
import { NotificationInner } from './style'

type NotificationProps = {
  children: ReactNode
  icon?: string
  className?: string
}

export const Notification: FC<NotificationProps> = ({ children, icon, className }) => {
  return (
    <NotificationInner className={className}>
      {icon && <Image src={icon} alt="lock" className="lock" />}
      {children}
    </NotificationInner>
  )
}
