import { createSlice } from '@reduxjs/toolkit'

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    isNotifications: false,
    isShowedNotifications: false,
    cryticalNotifications: [],
    isNotificationAlert: false
  },
  reducers: {
    setNotifications: (state, { payload }) => {
      state.isNotifications = payload
    },
    setCryticalNotifications: (state, { payload }) => {
      state.cryticalNotifications = payload
    },
    setShowedNotifications: (state, { payload }) => {
      state.isShowedNotifications = payload
    },
    setNotificationAlert: (state, { payload }) => {
      state.isNotificationAlert = payload
    }
  }
})

export const { setNotifications, setCryticalNotifications, setShowedNotifications, setNotificationAlert } =
  notificationsSlice.actions

export const getNotifications = (state: any) => state.notifications.isNotifications
export const getCryticalNotifications = (state: any) => state.notifications.cryticalNotifications
export const checkIsNotifications = (state: any) => state.notifications.isShowedNotifications
export const checkIsNotificationAlert = (state: any) => state.notifications.isNotificationAlert

export default notificationsSlice.reducer
