export const convertDate = (inputDate: string | null, separator: string = '-') => {
  if (inputDate) {
    let date = new Date(inputDate)
    let day = String(date.getUTCDate()).padStart(2, '0')
    let month = String(date.getUTCMonth() + 1).padStart(2, '0')
    let year = date.getUTCFullYear()

    return `${day}${separator}${month}${separator}${year}`
  } else return ''
}

export const getTimeByDateISO = (inputDate: string | null) => {
  if (inputDate) {
    return inputDate.match(/\d\d:\d\d/)
  } else return ''
}

export const formatDate = (inputDate: string): string => {
  const currentDate = new Date()
  const inputDateTime = new Date(inputDate)

  const isToday: boolean =
    currentDate.getDate() === inputDateTime.getDate() &&
    currentDate.getMonth() === inputDateTime.getMonth() &&
    currentDate.getFullYear() === inputDateTime.getFullYear()

  if (isToday) {
    const hours: string = inputDateTime.getHours().toString().padStart(2, '0')
    const minutes: string = inputDateTime.getMinutes().toString().padStart(2, '0')
    return `${hours}:${minutes}`
  } else {
    const months: string[] = [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря'
    ]

    const day: number = inputDateTime.getDate()
    const month: string = months[inputDateTime.getMonth()]

    return `${day} ${month}`
  }
}

export const formatDateWithTime = (inputDate: string): string => {
  const date = new Date(inputDate)

  const day = String(date.getUTCDate()).padStart(2, '0')
  const month = String(date.getUTCMonth() + 1).padStart(2, '0')
  const year = String(date.getUTCFullYear())
  const hours = String(date.getUTCHours()).padStart(2, '0')
  const minutes = String(date.getUTCMinutes()).padStart(2, '0')
  const seconds = String(date.getUTCSeconds()).padStart(2, '0')

  return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`
}

export const formatDateWithTimeByTimeZone = (inputDate: string): string => {
  const [datePart, timePart, timeZonePart] = inputDate.split(', ')

  // Разбиваем дату на компоненты
  const [day, month, year] = datePart.split('.')
  const [time, timezone] = timePart.split(' ')
  const [hours, minutes, seconds] = time.split(':')

  // Создаем объект Date с учетом часового пояса
  const inputDateTime = new Date(`${year}-${month}-${day}T${time} ${timezone}`)

  // Форматируем дату в нужный формат
  const outputDate = `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`

  return outputDate
}
