import { createApi } from '@reduxjs/toolkit/query/react'

import { customFetchBaseWithReauth } from '.'

export const historyOperationsApi = createApi({
  reducerPath: 'historyOperationsApi',
  baseQuery: (args, api, extraOptions) =>
    customFetchBaseWithReauth({ ...args, baseUrl: process.env.REACT_APP_BILLING_API_URL }, api, extraOptions),

  endpoints: (builder) => ({
    getTypeTransactions: builder.mutation<any, {}>({
      query: () => ({
        url: '/transaction/types',
        method: 'GET'
      })
    }),
    getTransactionsByID: builder.mutation<any, { id: string; params: URLSearchParams }>({
      query: ({ id, params }) => ({
        url: `/client/${id}/transactions?${params}`,
        method: 'GET'
      }),
      transformResponse: (response: { items: any }, meta, arg) => response?.items
    })
  })
})

export const { useGetTypeTransactionsMutation, useGetTransactionsByIDMutation } = historyOperationsApi
