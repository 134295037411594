import { FC, ReactNode } from 'react'

import { LabelInner } from './style'

interface LabelProps {
  children: ReactNode
}

export const Label: FC<LabelProps> = ({ children }) => {
  return <LabelInner>{children}</LabelInner>
}
