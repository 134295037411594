import { createSlice } from '@reduxjs/toolkit'

import { BillingClient } from '../@types/billingUserTypes'
import { getLocalStorageValue } from '../utils/localStorageHelpers'

const userBillingSlice = createSlice({
  name: 'userBilling',
  initialState: {
    billingSelectedDealerId: null,
    billingSelectedDealer: {},
    billingClientInfo: {},
    status: 'success',
    listClients: [],
    linkInvoicing: '',
    testAB: '0',
    isClientInfoResult: false,
    isSelectedDealerResult: false,
    isGlobalDownloadHomePage: false
  },
  reducers: {
    saveBillingSelectedDealerId: (state, action) => {
      return { ...state, billingSelectedDealerId: action?.payload }
    },
    saveBillingSelectedDealer: (state, action) => {
      return { ...state, billingSelectedDealer: action?.payload }
    },
    setStatus: (state, { payload }) => {
      state.status = payload
    },
    setListClients: (state, { payload }) => {
      state.listClients = payload
    },
    setLinkInvoicing: (state, { payload }) => {
      state.linkInvoicing = payload
    },
    setBillingClientInfo: (state, { payload }) => {
      state.billingClientInfo = payload
    },
    setIsClientInfoResult: (state, { payload }) => {
      state.isClientInfoResult = payload
    },
    setIsSelectedDealerResult: (state, { payload }) => {
      state.isSelectedDealerResult = payload
    },
    setABValue: (state, { payload }) => {
      state.testAB = payload
    },
    setGlobalDownloadHomePage: (state, { payload }) => {
      state.isGlobalDownloadHomePage = payload
    }
  }
})

export const {
  saveBillingSelectedDealerId,
  saveBillingSelectedDealer,
  setStatus,
  setListClients,
  setLinkInvoicing,
  setBillingClientInfo,
  setIsClientInfoResult,
  setIsSelectedDealerResult,
  setABValue,
  setGlobalDownloadHomePage
} = userBillingSlice.actions

export const selectUserBilling = (state: {
  userBilling: {
    status: string
    billingSelectedDealerId: string | null
    billingSelectedDealer: BillingClient
    billingClientInfo: any
    listClients: any
    linkInvoicing: string
    testAB: number | string
    isClientInfoResult: boolean
    isSelectedDealerResult: boolean
    isGlobalDownloadHomePage: boolean
  }
}) => state.userBilling

export default userBillingSlice.reducer
