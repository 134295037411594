import { format, intlFormat } from 'date-fns'
import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'

import { ClientUserAttributes } from '../../../@types/billingUserTypes'
import deleteIcon from '../../../assets/icons/delete.svg'
import pencil from '../../../assets/icons/pencil.svg'
import { setDataForForm, setIsNewEmployee } from '../../../store/editAddEmployeeSlice'
import { Button } from '../../../ui/button'
import { Image } from '../../../ui/image'
import { Popup } from '../../../ui/popup/Popup'
import { ControlsButton, DateWrapper, Email, EmployeeInner, EmployeeTitle, LastVisit, Text, TextPopup } from './style'

interface EmployeeProps {
  item: ClientUserAttributes
  openModal: (val: boolean) => void
  owner?: boolean
}

export const Employee: FC<EmployeeProps> = ({ item, openModal, owner = false }) => {
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false)
  const dispatch = useDispatch()

  return (
    <EmployeeInner owner={owner}>
      {isShowPopup && (
        <Popup closePopup={setIsShowPopup}>
          <TextPopup>Вы действительно хотите удалить сотрудника?</TextPopup>
          <Button>Удалить</Button>
        </Popup>
      )}
      {/* <ControlsButton>
        <Image
          src={pencil}
          alt="pencil"
          onClick={() => {
            openModal(true)
            dispatch(setIsNewEmployee(false))
            dispatch(setDataForForm(item))
          }}
        />
        {owner && <Image src={deleteIcon} alt="deleteIcon" onClick={() => setIsShowPopup(true)} />}
      </ControlsButton> */}
      <EmployeeTitle>{item?.last_name || '-'}</EmployeeTitle>
      <EmployeeTitle>{item?.first_name || '-'}</EmployeeTitle>
      <Email>{item?.email || '-'}</Email>
      {!owner && (
        <LastVisit>
          <Text>Был(а) в сети:</Text>
          <DateWrapper>
            {(item?.last_auth_time && format(new Date(item?.last_auth_time), 'dd.MM.yyyy')) || '-'}
          </DateWrapper>
        </LastVisit>
      )}
    </EmployeeInner>
  )
}
