//@ts-nocheck
import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { BillingAddress } from '../../@types/billingUserTypes'
import { useUpdateBillingClientAddressByIdMutation } from '../../api/billingUserApi'
import { BtnWrap, Fields } from '../../pages/registartion/style'
import { Button } from '../../ui/button'
import { Input } from '../../ui/input'
import { Label } from '../../ui/label'
import { Warning } from '../../ui/warning/Warning'
import {
  editAddressEdoValidationSchema,
  editAddressValidationSchema
} from '../../validation/editAddressValidationSchema'

interface EditAddressProps {
  id: number | undefined
  formData: BillingAddress | undefined
  isEdo: Boolean
  onClose: () => void
}

export const EditAddress: FC<EditAddressProps> = ({ id, formData, isEdo, onClose }) => {
  const {
    formState: { errors },
    register,
    handleSubmit,
    setError,
    control
  } = useForm({
    mode: 'all',
    resolver: yupResolver(isEdo ? editAddressEdoValidationSchema : editAddressValidationSchema),
    defaultValues: {
      name: formData?.name || '',
      receiver: formData?.receiver || '',
      // phone: '+7 999 999 99 99',
      address: formData?.address || '',
      email: formData?.email || ''
    }
  })

  const [
    updateBillingClientAddressById,
    {
      isLoading: isLoadingBillingUserById,
      data: billingUserById,
      isError: isErrorBillingUserById,
      error: billingUserByIdError,
      isSuccess: isSuccessBillingUserById
    }
  ] = useUpdateBillingClientAddressByIdMutation()

  useEffect(() => {
    if (billingUserById) {
      onClose()
    }
  }, [billingUserById])

  useEffect(() => {
    if (billingUserByIdError) {
      setError('email', { message: 'Возникла ошибка сервера при сохранении' })
    }
  }, [billingUserByIdError])

  const onSubmit = async (data: BillingAddress) => {
    if (id) {
      await updateBillingClientAddressById({ id, body: data })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!isEdo && (
        <>
          <Fields>
            <Label>Адрес</Label>
            <Input type="text" register={register} name={'address'} required={false} />
            {errors?.address && <Warning status={'error'}>{errors?.address?.message}</Warning>}
          </Fields>
          <Fields>
            <Label>Название организации</Label>
            <Input type="text" register={register} name={'name'} required={false} />
            {errors?.name && <Warning status={'error'}>{errors?.name?.message}</Warning>}
          </Fields>
          <Fields>
            <Label>Получатель</Label>
            <Input type="text" register={register} name={'receiver'} required={false} />
            {errors?.receiver && <Warning status={'error'}>{errors?.receiver?.message}</Warning>}
          </Fields>
        </>
      )}
      <Fields>
        <Label>Email</Label>
        <Input type="text" register={register} name={'email'} required />
        {errors?.email && <Warning status={'error'}>{errors?.email?.message}</Warning>}
      </Fields>
      {/* <Fields>
        <Label>Телефон</Label>
        <Controller
          control={control}
          name="phone"
          render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
            <MaskedInput
              mask="+7 (999) 999-99-99"
              maskChar="_"
              alwaysShowMask={false}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              error={error}
              name={'phone'}
            >
              {(inputProps: InputProps) => <Input {...inputProps} register={register} />}
            </MaskedInput>
          )}
        />
        {errors?.phone && <Warning status={'error'}>{errors?.phone?.message}</Warning>}
      </Fields> */}

      <BtnWrap>
        <Button disabled={isLoadingBillingUserById}>Сохранить</Button>
      </BtnWrap>
    </form>
  )
}
