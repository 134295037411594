import * as yup from 'yup'
import YupPassword from 'yup-password'

YupPassword(yup)

export const passwordValidation = yup.object().shape({
  password: yup
    .string()
    .required('Введите пароль')
    .min(8, 'Пароль не меньше 8 символов')
    .minLowercase(1, 'Должен содержать хотя бы 1 строчную букву')
    .minUppercase(1, 'Должен содержать хотя бы 1 заглавную букву')
    .minNumbers(1, 'Должен содержать хотя бы 1 цифру'),
  // TODO: если понадобится спец символ или удалить
  // .minSymbols(1, 'Должен содержать хотя бы 1 спец символ'),
  confirmPassword: yup
    .string()
    .required('Повторите пароль')
    .min(8, 'Пароль не меньше 8 символов')
    .minLowercase(1, 'Должен содержать хотя бы 1 строчную букву')
    .minUppercase(1, 'Должен содержать хотя бы 1 заглавную букву')
    .minNumbers(1, 'Должен содержать хотя бы 1 цифру')
    // TODO: если понадобится спец символ или удалить
    // .minSymbols(1, 'Должен содержать хотя бы 1 спец символ'),
    .oneOf([yup.ref('password')], 'Пароли не совпадают')
})

export const emailValidation = yup.string().email('Почта введена не верно').required('Введите почту')
