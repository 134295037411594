import React, { FC, useState } from 'react'
import { BiRuble } from 'react-icons/bi'
import { useSelector } from 'react-redux'

import { useSendUserClickEventMutation } from '../../api/userClickEventsApi'
import { CreateSoonText, CreateSoonWrapper, GreenText, RedText } from '../../assets/styles/global'
import { ContactLink } from '../../pages/contacts/style'
import { selectUserBilling } from '../../store/userBillingSlice'
import { Button } from '../../ui/button'
import { Popup } from '../../ui/popup/Popup'
import { Text, TitlePopup } from '../../ui/popup/style'
import { getLocalStorageValue } from '../../utils/localStorageHelpers'
import { numberWithSpaces } from '../../utils/numberWithSpaces'
import { Block, CardBalanceInner, Item, Rating, Title, Value, WrapBtn } from './style'

const componentByValue = {
  positive: GreenText,
  negative: RedText
}

export const CardBalance: FC<any> = ({ list, title, value, isBtn, isDevelop, handleClick, openPopup }) => {
  const ValueBox = componentByValue[value > 0 ? 'positive' : 'negative']
  const userBilling = useSelector(selectUserBilling)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [sendUserClickEvent] = useSendUserClickEventMutation()
  const userType = getLocalStorageValue('user')?.attributes?.type

  return (
    <>
      <CardBalanceInner>
        <CreateSoonWrapper>
          <Title isDevelop={isDevelop}>
            {title}
            <Rating>
              <ValueBox>
                {numberWithSpaces(value)?.toLocaleString()} <BiRuble />
              </ValueBox>
            </Rating>
          </Title>

          <Block>
            <div>
              <Item onClick={() => setIsOpen(true)}>Как мы рассчитываем цены?</Item>
              {list?.map((val: any, index: number) => (
                <Item key={index}>
                  {val?.text}

                  <Value
                    link={val.link}
                    isPositive={value > 0}
                    onClick={() => {
                      val.link && openPopup(true)
                      val.link && userType !== 'internal' && sendUserClickEvent('button_recommended_amount_click')
                    }}
                  >
                    {val?.value}
                    {val?.rub && <BiRuble />}
                  </Value>
                </Item>
              ))}
            </div>
            <WrapBtn>
              {userBilling?.listClients?.length > 0 && isBtn && <Button onClick={handleClick}>Пополнить счет</Button>}
            </WrapBtn>
          </Block>
        </CreateSoonWrapper>
        {isDevelop && (
          <CreateSoonText>
            <p>Выручка</p>
          </CreateSoonText>
        )}
      </CardBalanceInner>
      {isOpen && (
        <Popup closePopup={setIsOpen} background={true} isDanger={false} textAlign={'center'}>
          <TitlePopup size={'small'}>Почему мы показываем цены без НДС?</TitlePopup>
          <Text textAlign={'left'}>
            Мы показываем цены без НДС, чтобы обеспечить вам прозрачность и честное сравнение наших цен с другими B2B
            компаниями на рынке.
            <br />
            <br />
            В B2B сегменте НДС от поставщиков не является расходом для компаний использующих общую систему
            налогообложения (ОСНО). На сумму НДС уплаченную другим организациям за товары или услуги (так называемый
            входящий НДС) компании снижают свой собственной платёж по НДС налоговой. Это называется вычет по НДС. То
            есть для компании на ОСНО фактически нет разницы - что заплатить условные 100р НДС налоговой, что заплатить
            100р НДС другому бизнесу. По данным Интерфакс Спарк, около 98% наших российских клиентов используют общую
            систему налогообложения.
            <br />
            <br />
            Данная логика расчета используется и для наших зарубежных клиентов. За счёт единого формата отображения цен
            у нас отпадает необходимость создания отдельных версий личного кабинета / усложнения процессов по
            разработке.
            <br />
            <br />
            В целом, это решение помогает снизить затраты на администрирование и разработку и упрощает бухгалтерский
            учет. Это позволяет нам сосредотачивать ресурсы на улучшении качества наших услуг.
            <br />
            <br />
            Мы ценим ваше доверие и всегда готовы ответить на ваши вопросы. Пожалуйста, напишите нам на
            <ContactLink href="mailto:support@udpauto.ru"> support@udpauto.ru</ContactLink>, если у вас возникли вопросы
            по отображению цен.
          </Text>
          <Button
            onClick={() => {
              setIsOpen(false)
            }}
          >
            Понятно
          </Button>
        </Popup>
      )}
    </>
  )
}
