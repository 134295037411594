export const baseTheme = {
  colors: {
    accent: '#223361',
    accentDark: '#0E1F4D',
    basic: '#232323',
    success: '#3DB014',
    error: '#FF0000',

    black: '#000000',
    grey: '#CCCCCC',
    grey1: '#F7F8FA',
    grey2: '#D9D9D9',
    grey3: '#888888',
    grey4: '#C4C4C4',
    grey5: '#666666',
    grey6: '#aaaaaa',
    white: '#FFFFFF'
  },
  media: {
    extraLarge: '(max-width: 1440px)',
    grand: '(max-width: 1220px)',
    large: '(max-width: 1024px)',
    tablet: '(max-width: 992px)',
    medium: '(max-width: 768px)',
    small: '(max-width: 520px)'
  },
  title: {
    h1: {
      fontSize: '3.2rem',
      fontWeight: 700,
      lineHeight: '3.6rem'
    },
    h2: {
      fontSize: '2.4rem',
      fontWeight: 700,
      lineHeight: '2.8rem'
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: '2.8rem'
    }
  },
  body: {
    p1: {
      fontSize: '1.8rem',
      fontWeight: 400,
      lineHeight: '2.8rem'
    },
    p2: {
      fontSize: '1.6rem',
      fontWeight: 400,
      lineHeight: '2.2rem'
    },
    p3: {
      fontSize: '1.4rem',
      fontWeight: 400,
      lineHeight: '2rem'
    },
    p4: {
      fontSize: '1.3rem',
      fontWeight: 400,
      lineHeight: '1.8rem'
    },
    p5: {
      fontSize: '1.2rem',
      fontWeight: 400,
      lineHeight: '1.6rem'
    },
    btn: {
      fontSize: '1.4rem',
      lineHeight: '2.4rem',
      fontWeight: 700
    },
    label: {
      fontSize: '1.4rem',
      fontWeight: 400,
      lineHeight: '2rem'
    },
    input: {
      fontSize: '1.8rem',
      lineHeight: '2.8rem',
      fontWeight: 400
    }
  }
}
