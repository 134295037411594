import { createSlice } from '@reduxjs/toolkit'

import { UserDataType } from '../@types/userTypes'

const userSlice = createSlice({
  name: 'user',
  initialState: {
    id: '',
    type: '',
    attributes: {
      email: '',
      phone: '',
      first_name: '',
      last_name: '',
      company: '',
      position: '',
      one_platform_data: {
        id: '',
        email: '',
        auth_token: '',
        refresh_token: ''
      },
      smart_contract_data: {
        id: '',
        email: '',
        auth_token: '',
        refresh_token: ''
      },
      support_tracker_data: {
        id: '',
        email: '',
        auth_token: '',
        refresh_token: ''
      },
      telegram_showroom_data: {
        id: '',
        email: '',
        auth_token: '',
        refresh_token: ''
      }
    }
  },
  reducers: {
    saveUser: (state, action) => {
      return { ...state, ...action?.payload }
    },
    deleteUser: (state) => {
      state.id = ''
      state.type = ''
      state.attributes = {
        email: '',
        phone: '',
        first_name: '',
        last_name: '',
        company: '',
        position: '',
        one_platform_data: {
          id: '',
          email: '',
          auth_token: '',
          refresh_token: ''
        },
        smart_contract_data: {
          id: '',
          email: '',
          auth_token: '',
          refresh_token: ''
        },
        support_tracker_data: {
          id: '',
          email: '',
          auth_token: '',
          refresh_token: ''
        },
        telegram_showroom_data: {
          id: '',
          email: '',
          auth_token: '',
          refresh_token: ''
        }
      }
      return
    }
  }
})

export const { saveUser, deleteUser } = userSlice.actions

export const selectUser = (state: { user: UserDataType }) => state.user

export default userSlice.reducer
