//@ts-nocheck
import { CSSProperties, useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, Outlet } from 'react-router-dom'

import { useGetSitesInfoByClientMutation } from '../../api/userApi'
import { useSendUserClickEventMutation } from '../../api/userClickEventsApi'
import arrowUp from '../../assets/icons/arrowUp.svg'
import { AnalyticsContext } from '../../hooks/useAnalytics'
import { selectUserBilling } from '../../store/userBillingSlice'
import { Button } from '../../ui/button'
import { getLocalStorageValue } from '../../utils/localStorageHelpers'
import { Container, Nav, NavItems } from './style'

const tabs = [
  // { to: 'graphics', label: 'Аналитика' },
  { to: 'metrika', label: 'Тепловая карта' },
  { to: 'bi', label: 'BI аналитика' }
]

const setStyles = (currentPath: string): CSSProperties => {
  return {
    visibility: currentPath === '/analytics/metrika' ? 'visible' : 'hidden',
    pointerEvents: currentPath === '/analytics/metrika' ? 'auto' : 'none',
    opacity: currentPath === '/analytics/metrika' ? 1 : 0,
    transition: 'opacity 0.3s ease'
  }
}

export const AnalyticsLayout = () => {
  const [getSitesInfoByClient, { data: sitesInfoByClient }] = useGetSitesInfoByClientMutation()

  const currentPath = window.location.pathname
  const context = useContext(AnalyticsContext)
  const { billingSelectedDealerId } = useSelector(selectUserBilling)
  const [siteInfo, setSiteInfo] = useState({})
  const [sendUserClickEvent] = useSendUserClickEventMutation()
  const userType = getLocalStorageValue('user')?.attributes?.type

  if (!context) {
    throw new Error('AnalyticsLayout must be used within an AnalyticsProvider')
  }

  useEffect(() => {
    ;(async () => {
      if (billingSelectedDealerId) {
        getSitesInfoByClient(billingSelectedDealerId)
      }
    })()
  }, [billingSelectedDealerId])

  useEffect(() => {
    if (sitesInfoByClient?.data?.length) {
      setSiteInfo(sitesInfoByClient?.data[0])
    } else {
      setSiteInfo([])
    }
  }, [sitesInfoByClient])

  console.log(userType)

  const { triggerButtonClick } = context

  return (
    <Container>
      <Nav id="navigation">
        <div></div>
        <NavItems>
          {tabs.map(
            (tab) =>
              (tab.to !== 'bi' ||
                (siteInfo?.id && siteInfo?.counter_id && siteInfo?.udp_bi_analytics_verification_code)) && (
                <div key={tab.to}>
                  <NavLink
                    to={tab.to}
                    onClick={() => {
                      if (userType !== 'internal') {
                        switch (tab.to) {
                          case 'bi':
                            sendUserClickEvent('bi_analytics_click')
                            break
                          case 'metrika':
                            sendUserClickEvent('heatmap_analytics_click')
                            break
                          case 'graphics':
                            sendUserClickEvent('analytics_click')
                            break
                          default:
                            break
                        }
                      }
                    }}
                    className={({ isActive }) => (isActive ? 'analytic-tab active' : 'analytic-tab')}
                  >
                    {tab.label}
                  </NavLink>
                </div>
              )
          )}
        </NavItems>
        <Button
          className={context.buttonClicked ? '' : 'show'}
          variant="secondary"
          icon={arrowUp}
          onClick={triggerButtonClick}
          style={setStyles(currentPath)}
        />
      </Nav>
      <Outlet />
    </Container>
  )
}
