import { FC } from 'react'
import { useSelector } from 'react-redux'

import pencil from '../../../assets/icons/pencil.svg'
import { selectUser } from '../../../store/userSlice'
import { Image } from '../../../ui/image'
import { formatPhoneNumber } from '../../../utils/formatPhone'
import { Edit, Field, HeaderSettingsInner, Label, Position, User } from './style'

export const HeaderSettings: FC<{ openModal: (val: boolean) => void }> = ({ openModal }) => {
  const user = useSelector(selectUser)
  return (
    <HeaderSettingsInner>
      <Field>
        <User>
          {user?.attributes?.first_name} {user?.attributes?.last_name}
        </User>
        <Position>{user?.attributes?.position}</Position>
      </Field>
      <Field>
        <div>{user?.attributes?.email}</div>
        <Label>E-mail</Label>
      </Field>
      <Field>
        <div>{formatPhoneNumber(user?.attributes?.phone)}</div>
        <Label>Телефон</Label>
      </Field>
      <Edit>
        <Image src={pencil} alt="edit" onClick={() => openModal(true)} />
      </Edit>
    </HeaderSettingsInner>
  )
}
