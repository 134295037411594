import styled from '@emotion/styled/macro'

export const RegistrationInner = styled.div`
  width: 100%;
`

export const FormInner = styled.div<{ hideGreyBorder?: boolean }>`
  border: ${({ theme, hideGreyBorder }) => (hideGreyBorder ? 'initial' : `1px solid ${theme.colors.grey}`)};
  padding: 3.2rem 3.2rem 0 3.2rem;
  border-radius: 1rem;
  margin-bottom: 1.9rem;

  @media ${({ theme }) => theme.media.medium} {
    border: none;
    padding: 0 2.4rem 0 2.4rem;
  }
`

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3.2rem;
`

export const FieldsCheckbox = styled(Fields)`
  padding: 0 16px;

  @media ${({ theme }) => theme.media.medium} {
    padding: 0 31px;
  }
`

export const SubTitle = styled.div`
  ${({ theme }) => theme.body.p2};
  text-align: center;
  margin-bottom: 3.3rem;

  @media ${({ theme }) => theme.media.medium} {
    padding: 0 2.4rem;
  }
`

export const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 4rem;

  a {
    text-decoration: none;
  }
  button {
    margin: 0 1.2rem 2.4rem;

    @media ${({ theme }) => theme.media.medium} {
      margin-bottom: 1rem;
    }
  }
`
