import { FC, Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { useGetExtendedUsersByClientIdMutation } from '../../../api/userApi'
import { selectEditAddEmployee } from '../../../store/editAddEmployeeSlice'
import { selectUserBilling } from '../../../store/userBillingSlice'
import { selectUser } from '../../../store/userSlice'
import { Button } from '../../../ui/button'
import { Employee } from './Employee'
import { BtnWrap, ListEmployeeInner } from './style'

export const ListEmployee: FC<{ openModal: (val: boolean) => void }> = ({ openModal }) => {
  const user = useSelector(selectUser)
  const { billingSelectedDealerId } = useSelector(selectUserBilling)
  const { listEmployees } = useSelector(selectEditAddEmployee)

  const [
    getExtendedUsersByClientId,
    {
      isLoading: isLoadingExtendedUsersByClientId,
      data: extendedUsersByClientId,
      isError: isErrorExtendedUsersByClientId,
      error: extendedUsersByClientIdError,
      isSuccess: isSuccessExtendedUsersByClientId
    }
  ] = useGetExtendedUsersByClientIdMutation()

  useEffect(() => {
    ;(async () => {
      await getExtendedUsersByClientId({ id: billingSelectedDealerId || '' })
    })()
  }, [billingSelectedDealerId])

  const showOwnerEmployee = () => {
    const ownerEmployee = extendedUsersByClientId?.find((item) => item?.id === user?.id)

    if (ownerEmployee) {
      return <Employee item={ownerEmployee?.attributes} openModal={openModal} key={ownerEmployee?.id} owner />
    }
    return null
  }

  const showOtherEmployee = () => {
    const ownerEmployee = extendedUsersByClientId?.filter((item) => item?.id !== user?.id)

    if (!!ownerEmployee?.length) {
      return ownerEmployee?.map((item) => <Employee item={item?.attributes} openModal={openModal} key={item?.id} />)
    }
    return null
  }

  return (
    <Fragment>
      <ListEmployeeInner>
        {showOwnerEmployee()}
        {showOtherEmployee()}
        {/* {listEmployees?.map((item) => {
          return <Employee item={item} openModal={openModal} key={uuidv4()} />
        })} */}
      </ListEmployeeInner>
      {/* <BtnWrap>
        <Button variant={'secondary'}>Загрузить еще</Button>
      </BtnWrap> */}
    </Fragment>
  )
}
