import { useState } from 'react'

import Arrow from './../../assets/icons/arrowGray.svg'
import { SingleToggle, SliderToggle, ToggleDescription, ToggleTitle } from './style'

export const Toggle = ({ title, list, children }: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    // <SingleToggle onClick={() => setIsOpen(!isOpen)}>
    <SingleToggle>
      <ToggleTitle className={isOpen ? 'opened' : ''}>
        {/* <img src={Arrow} alt="" /> */}
        {title}
      </ToggleTitle>
      {isOpen ? <ToggleDescription>{list}</ToggleDescription> : null}
      <SliderToggle>{children}</SliderToggle>
    </SingleToggle>
  )
}
