import styled from '@emotion/styled/macro'

export const ContainerTable = styled.div<{ bg?: string; color?: string; bold?: boolean; fontSize?: string }>`
  background: ${({ bg }) => (bg ? bg : '#ffffff')};
  color: ${({ color }) => (color ? color : 'rgba(35, 35, 35, 1)')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '12px')};
  font-weight: ${({ bold }) => (bold ? '600' : '400')};
  display: flex;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 12px;
  line-height: 20px;
  padding: 24px 26px;

  &.home-documents {
    padding: 14px 16px;
  }

  &.header {
    padding: 16px 24px;
    margin-bottom: 16px;

    @media (max-width: 500px) {
      display: none;
    }
  }

  &.mobile {
    display: none;

    @media (max-width: 500px) {
      display: block;
    }
  }

  &.desktop {
    @media (max-width: 500px) {
      display: none;
    }
  }
`

export const ContainerTableEl = styled.span<{
  width?: string
  alignItems?: string
  color?: string
  fontSize?: string
  bold?: boolean
}>`
  width: ${({ width }) => (width ? width : 'auto')};
  color: ${({ color }) => (color ? color : 'rgba(35, 35, 35, 1)')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '12px')};
  font-weight: ${({ bold }) => (bold ? '600' : '400')};
  display: flex;
  justify-content: ${({ alignItems }) => (alignItems ? alignItems : 'flex-start')};
  align-items: center;

  &.home-table {
    button {
      min-width: auto;
      max-width: 100%;
    }
  }
`

export const Group = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`
