import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { useGetClientPlansMutation, useGetSeoPlansMutation, useGetSupportPlansMutation } from '../../../api/tariffsApi'
import { useSendUserClickEventMutation } from '../../../api/userClickEventsApi'
import pencil from '../../../assets/icons/pencil.svg'
import { selectUserBilling } from '../../../store/userBillingSlice'
import { Image } from '../../../ui/image'
import { getLocalStorageValue } from '../../../utils/localStorageHelpers'
import { Edit, HeaderTariffsInner, TariffsEl, TariffsGroup, TarrifsSubtitle, TarrifsTitle, Title } from './style'

export const HeaderTariffs = () => {
  const [getClientPlans, { data: clientPlans }] = useGetClientPlansMutation()
  const [getSupportPlans, { data: supportPlans }] = useGetSupportPlansMutation()
  const [getSeoPlans, { data: seoPlans }] = useGetSeoPlansMutation()
  const { billingSelectedDealer } = useSelector(selectUserBilling)

  const [sendUserClickEvent] = useSendUserClickEventMutation()
  const userType = getLocalStorageValue('user')?.attributes?.type

  const navigate = useNavigate()

  useEffect(() => {
    ;(async () => {
      if (billingSelectedDealer?.id) {
        await getClientPlans({ id: billingSelectedDealer.id })
        await getSupportPlans({ id: billingSelectedDealer.id })
        await getSeoPlans({ id: billingSelectedDealer.id })
      }
    })()
  }, [billingSelectedDealer])

  const activeLicenseTariffName = () => {
    return clientPlans?.find((plan: any) => plan.id === billingSelectedDealer?.plan.plan_id)?.name
  }

  const activeContentTariffName = () => {
    return supportPlans?.find((plan: any) => plan.id === billingSelectedDealer?.plan.support_plan_id)?.name
  }

  const activeSeoTariffName = () => {
    return seoPlans?.find((plan: any) => plan.id === billingSelectedDealer?.plan.seo_plan_id)?.name
  }

  return (
    <HeaderTariffsInner>
      <Title>Тарифы</Title>
      {activeLicenseTariffName() || activeContentTariffName() || activeSeoTariffName() ? (
        <TariffsGroup>
          <TariffsEl>
            <TarrifsTitle>{activeLicenseTariffName() ?? 'Нет информации'}</TarrifsTitle>
            <TarrifsSubtitle>Лицензия</TarrifsSubtitle>
          </TariffsEl>
          <TariffsEl>
            <TarrifsTitle>{activeContentTariffName() ?? 'Нет информации'}</TarrifsTitle>
            <TarrifsSubtitle>Контентные работы</TarrifsSubtitle>
          </TariffsEl>
          <TariffsEl>
            <TarrifsTitle>{activeSeoTariffName() ?? 'Нет информации'}</TarrifsTitle>
            <TarrifsSubtitle>SEO</TarrifsSubtitle>
          </TariffsEl>
        </TariffsGroup>
      ) : null}
      <Edit
        onClick={() => {
          if (userType !== 'internal') {
            sendUserClickEvent('settings_click_edit')
          }
          navigate('/tariffs')
        }}
      >
        <Image src={pencil} alt="edit" />
      </Edit>
    </HeaderTariffsInner>
  )
}
