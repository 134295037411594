import styled from '@emotion/styled/macro'

export const SingleToggle = styled.div`
  padding: 16px;
  background-color: #ffffff;
  // cursor: pointer;

  .tariffs-slider {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 24px;
    justify-items: center;
    margin: 0 -16px;
    align-items: center;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    .slick-slide {
      padding: 0 12px;

      @media (max-width: 1275px) {
        padding: 0 6px;
      }
    }

    // > div:nth-child(3) {
    //   background-color: rgba(217, 217, 217, 0.43);
    //   position: relative;

    //   &:before {
    //     content: '';
    //     width: 100%;
    //     height: 100vh;
    //     position: absolute;
    //     top: -50vh;
    //     background: #efefef;
    //     left: 0;
    //   }
    // }
  }
`

export const ToggleTitle = styled.p`
  justify-content: center;
  color: #232323;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 8px;

  img {
    transition: 0.1s;
  }

  &.opened img {
    transform: rotate(180deg);
  }
`

export const ToggleDescription = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.24px;
  color: #232323;
  margin-top: 9px;
`

export const SliderToggle = styled.div`
  margin-top: 16px;
`
