import React, { FC, Fragment } from 'react'
import { useSelector } from 'react-redux'

import error from '../../../assets/icons/error.svg'
import successCheck from '../../../assets/icons/successCheck.svg'
import { Title } from '../../../pages/home/style'
import { selectUserBilling } from '../../../store/userBillingSlice'
import { Button } from '../../button'
import { Image } from '../../image'
import { ImageModal, Text } from '../style'

export const ResponsePopup: FC<any> = ({ setIsShowPopup, successText, errorText }) => {
  const userBilling = useSelector(selectUserBilling)
  return (
    <Fragment>
      <ImageModal>
        {<Image src={userBilling?.status === 'error' ? error : successCheck} alt="successCheck" />}
      </ImageModal>
      {<Title>{userBilling?.status === 'error' ? errorText : 'Успешно!'}</Title>}
      {<Text>{userBilling?.status === 'error' ? 'Пожалуйста повторите попытку' : successText}</Text>}

      <Button onClick={() => setIsShowPopup(false)}>Вернуться в профиль</Button>
    </Fragment>
  )
}
