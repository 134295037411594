import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { UserNotification } from '../../@types/userTypes'
import {
  useGetAllUserNotificationsMutation,
  useGetCriticalUnreadNotificationsMutation,
  useMarkAllNotCriticalNotificationAsReadMutation,
  useMarkNotificationAsReadMutation
} from '../../api/userApi'
import { Container } from '../../assets/styles/global'
import { getNotifications } from '../../store/notificationsSlise'
import { setCryticalNotifications } from '../../store/notificationsSlise'
import { setShowedNotifications } from '../../store/notificationsSlise'
import { Popup } from '../../ui/popup/Popup'
import { Text, TitlePopup } from '../../ui/popup/style'
import { formatDate } from '../../utils/convertDate'
import {
  NotificationRow,
  NotificationsGroup,
  NotificationsHeader,
  NotificationsHeaderGroup,
  NotificationsMarkButton,
  NotificationsPagination,
  NotificationsPaginationButton,
  NotificationsPaginationSize,
  NotificationsTitle
} from './style'

export const Notifications = () => {
  const [getAllUserNotifications, { data: notifications, isLoading: isNotificationLoader }] =
    useGetAllUserNotificationsMutation()
  const [markNotificationAsRead, { data: notificationMarked }] = useMarkNotificationAsReadMutation()
  const [
    markAllNotCriticalNotificationAsRead,
    { data: markedAllNotCriticalNotifications, isLoading: isMarkAllNotCriticalNotificationAsReadLoading }
  ] = useMarkAllNotCriticalNotificationAsReadMutation()
  const [getCriticalUnreadNotifications, { data: notificationsCrytical, error }] =
    useGetCriticalUnreadNotificationsMutation()

  const [isShowPopup, setIsShowPopup] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const currentSize: number = 10

  const dispatch = useDispatch()
  const isNotifications = useSelector(getNotifications)

  const totalPageSize = () => {
    if (notifications?.meta?.total) {
      return Math.ceil(notifications.meta.total / currentSize)
    }
  }

  const nextPage = () => {
    if (currentPage !== totalPageSize()) {
      setCurrentPage(currentPage + 1)
    }
  }

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const paginationInfo = () => {
    const startVal = (currentPage - 1) * currentSize + 1
    const finishVal = currentPage * currentSize

    if (
      (notifications?.data.length < currentSize && currentPage !== 1) ||
      (notifications?.data.length < currentSize && currentPage === 1)
    ) {
      return `${notifications?.meta?.total}`
    }

    if (notifications?.data.length === 1) {
      return `${startVal}`
    }

    return `${startVal} - ${finishVal}`
  }

  const setModalInfo = async (notification: any) => {
    setModalTitle(notification.attributes.header)

    setModalContent(notification.attributes.body)
    setIsShowPopup(true)

    if (!notification?.attributes.is_read && notification?.attributes.level !== 2) {
      await markNotificationAsRead({ notificationId: notification.id })
    }
  }

  useEffect(() => {
    ;(async () => {
      await getAllUserNotifications({ size: currentSize, num: currentPage })
    })()
  }, [currentPage, notificationMarked, markedAllNotCriticalNotifications, isNotifications])

  useEffect(() => {
    ;(async () => {
      getCriticalUnreadNotifications()
    })()
  }, [currentPage])

  useEffect(() => {
    if (notificationsCrytical?.data.length) {
      dispatch(setCryticalNotifications(notificationsCrytical))
    }
  }, [notificationsCrytical])

  useEffect(() => {
    dispatch(setShowedNotifications(isShowPopup))
  }, [isShowPopup])

  return (
    <Container>
      <NotificationsGroup>
        <NotificationsHeader>
          <NotificationsTitle>История уведомлений</NotificationsTitle>
          <NotificationsHeaderGroup className="full">
            <NotificationsMarkButton
              disabled={isMarkAllNotCriticalNotificationAsReadLoading}
              onClick={async () => await markAllNotCriticalNotificationAsRead()}
            >
              Прочитать все
            </NotificationsMarkButton>
            <NotificationsHeaderGroup>
              <NotificationsPaginationSize>
                {paginationInfo()} из {notifications?.meta?.total}
              </NotificationsPaginationSize>
              <NotificationsPagination>
                <NotificationsPaginationButton onClick={prevPage} disabled={currentPage === 1 || isNotificationLoader}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M14 7L9 12"
                      stroke="#292929"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9 12L14 17"
                      stroke="#292929"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </NotificationsPaginationButton>
                <NotificationsPaginationButton
                  onClick={nextPage}
                  disabled={currentPage === totalPageSize() || isNotificationLoader}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M10 17L15 12"
                      stroke="#292929"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M15 12L10 7"
                      stroke="#292929"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </NotificationsPaginationButton>
              </NotificationsPagination>
            </NotificationsHeaderGroup>
          </NotificationsHeaderGroup>
        </NotificationsHeader>
        {notifications?.data?.map((notification: UserNotification) => (
          <NotificationRow
            key={notification.id}
            className={notification.attributes.is_read ? '' : 'new'}
            onClick={() => setModalInfo(notification)}
          >
            <p>{notification?.attributes.header}</p>
            <span>{formatDate(notification?.attributes.created_at)}</span>
          </NotificationRow>
        ))}
      </NotificationsGroup>

      {isShowPopup && (
        <Popup className={'modal-notifications'} closePopup={() => setIsShowPopup(false)}>
          <TitlePopup>{modalTitle}</TitlePopup>
          <Text dangerouslySetInnerHTML={{ __html: modalContent }}></Text>
        </Popup>
      )}
    </Container>
  )
}
