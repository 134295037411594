import { FC, useEffect } from 'react'

import { Button } from '../../ui/button'
import { setLocalStorageValue } from '../../utils/localStorageHelpers'
import { CookieInner } from './style'

export const Cookie: FC<{ setIsShowCookie: (val: boolean) => void }> = ({ setIsShowCookie }) => {
  const handleClick = () => {
    setLocalStorageValue('isShowCookie', false)
    setIsShowCookie(false)
  }
  return (
    <CookieInner>
      Сайт использует файлы cookie. Cookie запоминают ваши действия и предпочтения для лучшего взаимодействия в
      Интернете.
      <Button size={'medium'} onClick={handleClick}>
        Ок
      </Button>
    </CookieInner>
  )
}
