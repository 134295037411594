import { FC, useEffect } from 'react'

import { useGetUserDataMutation } from '../../api/userApi'
import { useSendUserClickEventMutation } from '../../api/userClickEventsApi'
import check_green from '../../assets/icons/check_green.svg'
import { GreyText, RedText } from '../../assets/styles/global'
import { useAuth } from '../../hooks/useAuth'
import { Image } from '../../ui/image'
import { getLocalStorageValue } from '../../utils/localStorageHelpers'
import { CardInner, Connected, Text, Title } from './style'

export const Card: FC<{
  id: string
  title: string
  activeEvent: string
  text: string
  connected: {
    active: boolean
    text: string
    errorText?: string
  }
  link?: string | undefined
  openPopup?: any
  icon: string
}> = ({ title, activeEvent, text, connected, link, openPopup, icon, id }) => {
  // @ts-ignore
  const { setUserData } = useAuth()
  const [
    getUserData,
    {
      isLoading: isLoadingUserData,
      data: userData,
      isError: isErrorUserData,
      error: userDataError,
      isSuccess: isSuccessUserData
    }
  ] = useGetUserDataMutation()

  const [sendUserClickEvent] = useSendUserClickEventMutation()

  const userType = getLocalStorageValue('user')?.attributes?.type

  useEffect(() => {
    if (userData && !userDataError) {
      setUserData(userData)
    }
  }, [userData, userDataError])

  return (
    <CardInner
      as={link ? 'a' : 'div'}
      href={link}
      target={link && '_blank'}
      onClick={(e) => {
        if ((id === 'oneplatform' || id === 'home_card_2' || id === 'home_card_3') && link) {
          e.preventDefault()
          // @ts-ignore
          getUserData().then((response) => {
            const urlWithHash = link && new URL(link) /*исходный урл в объекте*/
            const urlWithHashSplit = link?.split('/?') /*Разбитие на две строки по /?*/
            const newUrl =
              urlWithHash &&
              urlWithHashSplit &&
              urlWithHash.origin + '/?' + urlWithHashSplit[1] /*Слепливание нового урла без #*/
            const url = newUrl && new URL(newUrl)

            let finalUrl = ''

            if ((id === 'oneplatform' || id === 'home_card_2') && url) {
              // @ts-ignore
              url.searchParams.set('udp_token', response?.data?.attributes?.one_platform_data?.auth_key)
              finalUrl = url && url.origin + '/#/' + url.search
            }

            if (id === 'home_card_3' && url) {
              // @ts-ignore
              url.searchParams.set('udp_token', response?.data?.attributes?.smart_contract_data?.auth_key)
              finalUrl = urlWithHashSplit && urlWithHashSplit[0] + url.search
            }

            setTimeout(() => {
              window.open(finalUrl)
            }, 0)
          })
        }

        link && userType !== 'internal' && sendUserClickEvent(activeEvent)

        return !link && openPopup(true)
      }}
      icon={icon}
    >
      <Title>{title}</Title>
      <Text>{text}</Text>
    </CardInner>
  )
}
