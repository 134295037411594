//@ts-nocheck
import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import MaskedInput from 'react-input-mask'
import { useDispatch, useSelector } from 'react-redux'

import { RegistrationFormStepMainData } from '../../@types/forms'
import { InputProps } from '../../@types/formsUI'
import { useUserLoginMutation } from '../../api/userApi'
import { BtnWrap, Fields } from '../../pages/registartion/style'
import { addEmployee, selectEditAddEmployee } from '../../store/editAddEmployeeSlice'
import { selectUser } from '../../store/userSlice'
import { Button } from '../../ui/button'
import { Input } from '../../ui/input'
import { Label } from '../../ui/label'
import { Warning } from '../../ui/warning/Warning'
import { registrationStepMainDataValidationSchema } from '../../validation/registrationValidationSchema'

export const EditAddEmployee: FC<{ closePopup: (val: boolean) => void }> = ({ closePopup }) => {
  const dispatch = useDispatch()
  const { isNewEmployee, attributes } = useSelector(selectEditAddEmployee)
  let defaultValues = isNewEmployee ? undefined : attributes
  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
    getValues
  } = useForm({
    mode: 'all',
    resolver: yupResolver(registrationStepMainDataValidationSchema),
    defaultValues
  })
  const onSubmit = () => {
    dispatch(addEmployee(getValues()))
    closePopup(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Fields>
        <Label>Имя</Label>
        <Input type="text" register={register} name={'firstName'} required />
        {errors?.firstName && <Warning status={'error'}>{errors?.firstName?.message}</Warning>}
      </Fields>

      <Fields>
        <Label>Фамилия</Label>
        <Input type="text" register={register} name={'lastName'} required />
        {errors?.lastName && <Warning status={'error'}>{errors?.lastName?.message}</Warning>}
      </Fields>

      <Fields>
        <Label>Компания</Label>
        <Input type="text" register={register} name={'company'} required />
        {errors?.company && <Warning status={'error'}>{errors?.company?.message}</Warning>}
      </Fields>

      <Fields>
        <Label>Должность</Label>
        <Input type="text" register={register} name={'position'} required />
        {errors?.position && <Warning status={'error'}>{errors?.position?.message}</Warning>}
      </Fields>

      <Fields>
        <Label>Email</Label>
        <Input type="text" register={register} name={'email'} required />
        {errors?.email && <Warning status={'error'}>{errors?.email?.message}</Warning>}
      </Fields>

      <Fields>
        <Label>Телефон</Label>
        <Controller
          control={control}
          name="phone"
          render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
            <MaskedInput
              mask="+7 (999) 999-99-99"
              maskChar="_"
              alwaysShowMask={false}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              error={error}
              name={'phone'}
            >
              {(inputProps: InputProps) => <Input {...inputProps} register={register} />}
            </MaskedInput>
          )}
        />

        {errors?.phone && <Warning status={'error'}>{errors?.phone?.message}</Warning>}
      </Fields>

      <BtnWrap>
        <Button>Сохранить</Button>
      </BtnWrap>
    </form>
  )
}
