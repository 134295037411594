import styled from '@emotion/styled/macro'

export const CardBalanceWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2.4rem;
  margin-bottom: 3.9rem;

  @media ${({ theme }) => theme.media.grand} {
    grid-template-columns: 1fr;
    grid-row-gap: 2.4rem;
  }

  @media ${({ theme }) => theme.media.medium} {
    background: ${({ theme }) => theme.colors.grey1};
  }
`

export const CardBalanceInner = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  padding: 2.5rem 1.6rem 1.7rem;
  ${({ theme }) => theme.body.p2};
  position: relative;
  flex-grow: 1;
`

export const Block = styled.div`
  padding-top: 1.6rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;
`

export const Title = styled.div<{ isDevelop: boolean }>`
  ${({ theme }) => theme.title.h2};
  color: ${({ theme, isDevelop }) => (isDevelop ? 'transparent' : theme.colors.accent)};
  font-family: 'Helvetica', sans-serif;
  display: flex;
  gap: 1.6rem;
  justify-content: space-between;
  font-weight: 400;

  img {
    cursor: pointer;
  }
`

export const Rating = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-left: 5px;
  }
`

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.grey3};
  margin-bottom: 0.8rem;
  flex-wrap: wrap;
  gap: 0.6rem;

  :first-child {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.grey2};

    :hover {
      color: ${({ theme }) => theme.colors.grey3};
    }
  }
`

export const Value = styled.div<{ link: boolean; isPositive: boolean }>`
  color: ${({ theme, link, isPositive }) => {
    if (isPositive && link) return theme.colors.accent
    return theme.colors.basic
  }};
  text-align: right;
  display: flex;
  align-items: center;
  border-bottom: ${({ theme, link }) => {
    if (link) return `1px dashed ${theme.colors.accent}`
    return 'none'
  }};
  cursor: ${({ link }) => (link ? `pointer` : 'initial')};
`

export const Balance = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 2.4rem;
`

export const WrapBtn = styled.div`
  margin-top: 1.5rem;

  button {
    @media ${({ theme }) => theme.media.small} {
      width: 100%;
    }
  }
`
