// @ts-nocheck

import { useEffect, useState } from 'react'
import { BiRuble } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { FilterDocuments } from '../../@types/documents'
import { downloadDocumentFile } from '../../api/billingUtils'
import { useGetDocsByIDMutation } from '../../api/documentsApi'
import { ReactComponent as IInCircle } from '../../assets/icons/iInCircle.svg'
import { Container } from '../../assets/styles/global'
import { BACKEND_CODES } from '../../constants/backendCodes'
import { selectUserBilling } from '../../store/userBillingSlice'
import { Button } from '../../ui/button'
import { convertDate } from '../../utils/convertDate'
import { downloadFile, setFileDocumentName } from '../../utils/fileHandler'
import { NotificationListClients } from '../settings/style'
import { ContainerTable, ContainerTableEl, Group } from './style'

export const Documentation = () => {
  const navigate = useNavigate()

  const { billingSelectedDealer } = useSelector(selectUserBilling)

  const [filter, setFilter] = useState<FilterDocuments | {}>({})

  const [getDocsByID, { data: documents, isError: isErrorDocuments, error: errorDocuments }] = useGetDocsByIDMutation()

  const [isLoading, setLoader] = useState<boolean>(false)

  const docTypes = ['Счет', 'Акт', 'Лицензии', 'Учредительные документы', 'Акт сверки', 'УПД']

  useEffect(() => {
    setFilter({
      type: 5,
      from: new Date('2023-11-01 00:00:00'.replace(/\s+/g, 'T').concat('.000+08:00')).toISOString(),
      to: new Date().toISOString()
    })
  }, [])

  useEffect(() => {
    ;(async () => {
      if (billingSelectedDealer && !!Object.keys(filter).length) {
        getDocs()
      }
    })()
  }, [billingSelectedDealer, filter])

  // useEffect(() => {
  //   if (isErrorDocuments && errorDocuments?.status === BACKEND_CODES.accsess_token_expired) {
  //     alert('Доступ запрещен, смените клиента')
  //     navigate('/home')
  //   }
  // }, [isErrorDocuments])

  const getDocs = async () => {
    const params = new URLSearchParams()

    params.append('filter', JSON.stringify(filter))

    await getDocsByID({
      id: billingSelectedDealer.id,
      params: params.toString()
    })
  }

  const downloadDocument = async (id: number, report_id?: number, name: string, date: string) => {
    setLoader(true)

    const blobData = await downloadDocumentFile({
      id: billingSelectedDealer.id,
      file_id: id,
      report_id: report_id ? report_id : null
    })

    setLoader(false)

    downloadFile(blobData, setFileDocumentName(name, date))
  }

  return (
    <Container>
      {new Date(billingSelectedDealer?.active_from)?.getTime() - new Date('10.01.2023').getTime() < 0 ? (
        <NotificationListClients>
          <IInCircle />
          Документы сформированные до 01.10.2023 вы можете запросить у вашего аккаунт-менеджера
        </NotificationListClients>
      ) : null}

      <ContainerTable className="header" bg="rgba(226, 228, 235, 1)">
        <ContainerTableEl color="rgba(34, 51, 97, 1)" bold={true} fontSize="14px" width="35%">
          Наименование
        </ContainerTableEl>
        <ContainerTableEl color="rgba(34, 51, 97, 1)" bold={true} fontSize="14px" width="20%">
          Дата
        </ContainerTableEl>
        <ContainerTableEl color="rgba(34, 51, 97, 1)" bold={true} fontSize="14px" width="20%">
          Сумма
        </ContainerTableEl>
      </ContainerTable>

      {documents?.items?.length
        ? documents?.items?.map((document) => (
            <div key={document.id}>
              <ContainerTable className="desktop">
                <ContainerTableEl width="35%">{`${docTypes[document.typeNum]} ${document.name}`}</ContainerTableEl>
                <ContainerTableEl width="20%">{convertDate(document.dt, '.')}</ContainerTableEl>
                <ContainerTableEl width="20%">
                  {document.sum} <BiRuble />{' '}
                </ContainerTableEl>
                <ContainerTableEl width="25%" alignItems="flex-end">
                  <Button
                    disabled={isLoading}
                    size="medium"
                    onClick={() =>
                      downloadDocument(document.id, document?.idReport, document.name, convertDate(document.dt, '-'))
                    }
                  >
                    Скачать
                  </Button>
                </ContainerTableEl>
              </ContainerTable>

              <ContainerTable key={document.id} className="mobile">
                <Group>
                  <ContainerTableEl color="rgba(34, 51, 97, 1)" bold={true} fontSize="14px">
                    Наименование
                  </ContainerTableEl>
                  <ContainerTableEl fontSize="14px">{`${docTypes[document.typeNum]} ${
                    document.name
                  }`}</ContainerTableEl>
                </Group>
                <Group>
                  <ContainerTableEl color="rgba(34, 51, 97, 1)" bold={true} fontSize="14px">
                    Дата
                  </ContainerTableEl>
                  <ContainerTableEl fontSize="14px">{convertDate(document.dt, '.')}</ContainerTableEl>
                </Group>
                <Group>
                  <ContainerTableEl color="rgba(34, 51, 97, 1)" bold={true} fontSize="14px">
                    Сумма
                  </ContainerTableEl>
                  <ContainerTableEl fontSize="14px">
                    {document.sum} <BiRuble />
                  </ContainerTableEl>
                </Group>
                <ContainerTableEl alignItems="center" style={{ marginTop: '20px' }}>
                  <Button
                    disabled={isLoading}
                    size="medium"
                    onClick={() =>
                      downloadDocument(document.id, document?.idReport, document.name, convertDate(document.dt, '-'))
                    }
                  >
                    Скачать
                  </Button>
                </ContainerTableEl>
              </ContainerTable>
            </div>
          ))
        : null}
    </Container>
  )
}
