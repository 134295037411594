import styled from '@emotion/styled/macro'

export const ListEmployeeInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  margin-top: 2.4rem;
`

export const EmployeeInner = styled.div<{ owner: boolean }>`
  background: ${({ theme }) => theme.colors.white};
  border: ${({ theme, owner }) => {
    if (owner) return `1px solid ${theme.colors.accent}`
    return '1px solid transparent'
  }};
  padding: 1.6rem;
  border-radius: 0.8rem;
  width: calc(33.3333% - 1.6rem);
  position: relative;
  margin-bottom: 2.4rem;
  margin-right: 2.4rem;

  &:nth-of-type(3n) {
    margin-right: 0;
  }

  @media ${({ theme }) => theme.media.extraLarge} {
    width: calc(50% - 1.6rem);
    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }
  @media ${({ theme }) => theme.media.large} {
    width: 100%;
    &:nth-of-type(n) {
      margin-right: 0;
    }
  }
  @media ${({ theme }) => theme.media.small} {
    margin-bottom: 1.6rem;
  }
`

export const ControlsButton = styled.div`
  position: absolute;
  top: 2rem;
  right: 1.5rem;
  display: flex;
  img {
    margin-left: 1rem;
    cursor: pointer;
  }
`

export const EmployeeTitle = styled.div`
  ${({ theme }) => theme.title.h3};
  font-weight: 400;
  letter-spacing: -0.3px;
`

export const Email = styled.div`
  ${({ theme }) => theme.body.p2};
  color: ${({ theme }) => theme.colors.grey5};
  margin-top: 0.4rem;
  margin-bottom: 1.2rem;
`

export const LastVisit = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.body.p2};
`

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.grey6};
`

export const TextPopup = styled.div`
  ${({ theme }) => theme.body.p2};
  margin-bottom: 1.5rem;

  @media ${({ theme }) => theme.media.medium} {
    font-size: 2rem;
  }
`

export const DateWrapper = styled.div``

export const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.8rem;
  margin-bottom: 1.6rem;
`
