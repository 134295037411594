import { createSlice } from '@reduxjs/toolkit'

const redirectSlice = createSlice({
  name: 'redirect',
  initialState: {
    from: null
  },
  reducers: {
    productFrom: (state, action) => {
      state.from = action.payload
    }
  }
})

export const { productFrom } = redirectSlice.actions

export const selectProductFrom = (state: any) => state.redirect.from

export default redirectSlice.reducer
