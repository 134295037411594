import styled from '@emotion/styled/macro'

export const AlertContainer = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  border-radius: 4px;
  top: 50px;
  padding: 6px 16px;
  color: #ffffff;

  &.success {
    background: #2e7d32;
  }

  &.info {
    background: #0288d1;
  }

  &.warning {
    background: #ed6c02;
  }

  &.error {
    background: #d32f2f;
  }

  @media (max-width: 500px) {
    width: 95vw;
    top: 100px;
  }
`

export const Content = styled.p`
  font-size: 18px;
  padding: 8px 0;

  a {
    font-weight: 900;
    text-decoration: underline;
  }

  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 22px;
  }
`
