// @ts-ignore
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip
} from 'chart.js'
import html2pdf from 'html2pdf.js'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { Bar, Doughnut, Line } from 'react-chartjs-2'
import DatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import { useGetBillingClientByIdMutation } from '../../api/billingUserApi'
import { downloadBillingAnalyticsFile } from '../../api/billingUtils'
import { useGetBillingAllAnalyticsDailyByIdMutation } from '../../api/userApi'
import { useGetSitesInfoByClientMutation } from '../../api/userApi'
import { useSendUserClickEventMutation } from '../../api/userClickEventsApi'
import { Container } from '../../assets/styles/global'
import { selectUserBilling } from '../../store/userBillingSlice'
import { selectUser } from '../../store/userSlice'
import { Button } from '../../ui/button'
import { Image } from '../../ui/image'
import { Loader } from '../../ui/loader'
import { LoaderContainer } from '../../ui/loader/style'
import { Logo } from '../../ui/logo'
import { convertDate } from '../../utils/convertDate'
import { downloadFile, setFileName } from '../../utils/fileHandler'
import { getLocalStorageValue } from '../../utils/localStorageHelpers'
import arrowUp from './../../assets/icons/arrowUp.svg'
import download from './../../assets/icons/download.svg'
import filter from './../../assets/icons/filter.svg'
import iInCircleBlue from './../../assets/icons/iInCircleBlue.svg'
import pdf from './../../assets/icons/pdf.svg'
import share from './../../assets/icons/share.svg'
import HeaderPDF from './../../assets/images/pdf-header.png'
import {
  AnalyticsInner,
  Buttons,
  FilterByDate,
  GotoBack,
  Header,
  IframeContainer,
  Item,
  LogoWrap,
  Name,
  PdfContainer,
  Row,
  Share,
  SubTitle,
  Title,
  TooltipContent,
  TooltipWrapper,
  Wrap
} from './style'

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale, LineElement, PointElement, Filler, BarElement, CategoryScale)

export const Analytics = () => {
  const tooltipRef = useRef<HTMLDivElement | null>(null)
  const navigate = useNavigate()
  const [dateRange, setDateRange] = useState([null, null])
  const [tooltip, setTooltip] = useState<string | null>(null)
  const [billingAnalyticsDailyData, setBillingAnalyticsDailyData] = useState<any>({ labels: [], datasets: [] })
  const [getSitesInfoByClient, { data: sitesInfoByClient }] = useGetSitesInfoByClientMutation()
  const [trafficGraphData, setTrafficGraphData] = useState<any>({ labels: [], datasets: [] })
  const [viewedPagesGraphData, setViewedPagesGraphData] = useState<any>({ labels: [], datasets: [] })
  const [currentVINGraphData, setCurrentVINGraphData] = useState<any>({ labels: [], datasets: [] })
  const [currentRequestGraphData, setCurrentRequestGraphData] = useState<any>({ labels: [], datasets: [] })
  const [isPdfLoader, setPdfLoader] = useState<boolean>(false)
  const [isDataLoader, setDataLoader] = useState<boolean>(false)
  const [startDate, endDate] = dateRange
  const { billingSelectedDealerId, billingSelectedDealer } = useSelector(selectUserBilling)
  const user = useSelector(selectUser)
  const [getBillingAllAnalyticsDailyById, { data, isSuccess, isLoading, isError }] =
    useGetBillingAllAnalyticsDailyByIdMutation()
  const [getBillingClientById, { data: billingClientById }] = useGetBillingClientByIdMutation()
  const [sendUserClickEvent] = useSendUserClickEventMutation()
  const days = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб']
  const months = [
    'январь',
    'февраль',
    'март',
    'апрель',
    'май',
    'июнь',
    'июль',
    'август',
    'сентябрь',
    'октябрь',
    'ноябрь',
    'декабрь'
  ]
  const locale = {
    localize: {
      day: (n: any) => days[n],
      month: (n: any) => months[n]
    },
    formatLong: {
      date: () => 'mm/dd/yyyy'
    }
  }

  const userType = getLocalStorageValue('user')?.attributes?.type

  const FilterByDateComponents = forwardRef(({ value, onClick }: any, ref: any) => (
    <FilterByDate onClick={onClick} ref={ref}>
      <span>Фильтровать по дате</span> <Image src={filter} alt="name" />
    </FilterByDate>
  ))

  const handleDownloadClick = async () => {
    setDataLoader(true)
    try {
      const params = new URLSearchParams()

      if (startDate && endDate) {
        params.append('date_from', convertDate(startDate))
        params.append('date_to', convertDate(endDate))
      } else if (startDate) {
        params.append('date_from', convertDate(startDate))
        params.append('date_to', convertDate(startDate))
      }

      const blobData = await downloadBillingAnalyticsFile({
        billingId: billingSelectedDealerId,
        format: 'xlsx',
        params
      })

      const dataLabels = endDate ? [convertDate(startDate), convertDate(endDate)] : [convertDate(startDate)]

      downloadFile(blobData, setFileName(billingClientById?.name, dataLabels))
      setDataLoader(false)
    } catch (error) {
      console.error('Error:', error)
      setDataLoader(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [tooltip])

  useEffect(() => {
    ;(async () => {
      if (billingSelectedDealer?.id) {
        //@ts-ignore
        getSitesInfoByClient(billingSelectedDealer?.id)
      }
    })()
  }, [billingSelectedDealer])

  const handleClickOutside = (event: { target: any }) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setTooltip(null)
    }
  }

  const handleTooltipClick = (id: string) => {
    setTooltip(tooltip === id ? null : id)
  }

  const handleOpenPDF = () => {
    setPdfLoader(true)

    let worker = new html2pdf()
    let newContent = document.createElement('div')
    newContent.innerHTML = `
      <div style="background-color: #233261; height: 816px;">
        <h1
          style="
            line-height: 50px;
            color: #ffffff;
            position: absolute;
            top: 200px;
            left: 70px;
            font-family: 'Helvetica';
            font-size: 40px;
          "
        >
          <span style="color: #f18801;">АНАЛИТИЧЕСКИЙ ОТЧЕТ</span><br> ДИЛЕРСКОГО ЦЕНТРА <br>${
            billingClientById?.name
          }</h1>
          <span style="font-size: 18px; color: #ffffff; position: absolute; left: 70px; top: 380px;">
            ${
              handleCloseDatepicker()
                ? '<p>с ' + convertDate(startDate, '.') + ' по ' + convertDate(endDate, '.') + '</p>'
                : '<p> за ' + convertDate(startDate, '.') + '</p>'
            }
          </span>
          <span style="font-size: 15px; opacity: 0.8; position: absolute; top: 750px; right: 70px; color: #dbdfe5;">${new Date().getFullYear()}</span>
        <img style="width: 100%; height: 600px;" src="${HeaderPDF}">
      <div>
    `
    const breakElements = document.querySelectorAll('.break')
    const hideElements = document.querySelectorAll('.hidden')
    const columnElements = document.querySelectorAll('.flex-column')
    const itemHalfPageElements = document.querySelectorAll('.flex-column .break')
    const firstPdfElement = document.querySelectorAll('.first-pdf-element')
    const chartsTitle = document.querySelectorAll('.charts-title')
    const canvases = document.querySelectorAll('canvas')

    hideElements.forEach((el: any) => {
      el.style.display = 'none'
    })

    breakElements.forEach((el: any) => {
      el.style.padding = '50px'
    })

    firstPdfElement.forEach((el: any) => {
      el.style.padding = '50px'
    })

    columnElements.forEach((el: any) => {
      el.style.flexDirection = 'column'
    })

    itemHalfPageElements.forEach((el: any) => {
      el.style.width = '100%'
    })

    chartsTitle.forEach((el: any) => {
      el.style.fontSize = '2.5rem'
      el.style.width = '100%'
      el.style.textAlign = 'center'

      el.lastElementChild.style.display = 'none'
    })

    canvases.forEach((el) => {
      el.style.scale = '1.3'
      el.style.marginTop = '60px'
    })

    const opt = {
      margin: 0,
      jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' },
      pagebreak: { before: '.break' }
    }

    worker
      .set(opt)
      .from(newContent)
      .toPdf()
      .get('pdf')
      .then(function (pdf: any) {
        pdf.addPage()
      })
      .from(document.getElementById('pdf-place'))
      .toCanvas()
      .toPdf()
      .get('pdf')
      .then(function (pdf: any) {
        // window.open(pdf.output('bloburl'), '_blank')
        if (endDate) {
          pdf.save(`presentation_${billingClientById?.name}_${convertDate(startDate)}-${convertDate(endDate)}`)
        } else {
          pdf.save(`presentation_${billingClientById?.name}_${convertDate(startDate)}`)
        }
        breakElements.forEach((el: any) => {
          el.style.padding = '0 1.9rem'
        })
        firstPdfElement.forEach((el: any) => {
          el.style.padding = '0 1.9rem'
        })
        hideElements.forEach((el: any) => {
          el.style.display = 'block'
        })
        columnElements.forEach((el: any) => {
          el.style.flexDirection = 'row'
        })

        itemHalfPageElements.forEach((el: any) => {
          el.style.width = '50%'
        })

        chartsTitle.forEach((el: any) => {
          el.style.fontSize = '3.5rem'
          el.style.width = 'auto'
          el.style.textAlign = 'left'

          el.lastElementChild.style.display = 'inline-block'
        })

        canvases.forEach((el) => {
          el.style.scale = '1'
          el.style.marginTop = '0'
        })

        setPdfLoader(false)
      })
  }

  useEffect(() => {
    setDateRange([new Date().setDate(1), new Date() as any])
  }, [])

  useEffect(() => {
    ;(async () => {
      if (billingSelectedDealerId) {
        await getBillingClientById({ id: billingSelectedDealerId })
      }
    })()
  }, [billingSelectedDealerId])

  useEffect(() => {
    if (!billingSelectedDealerId) return

    const params = new URLSearchParams()
    if (startDate && endDate) {
      params.append('date_from', convertDate(startDate))
      params.append('date_to', convertDate(endDate))
    } else if (startDate) {
      params.append('date_from', convertDate(startDate))
      params.append('date_to', convertDate(startDate))
    }

    ;(async () => {
      await getBillingAllAnalyticsDailyById({
        id: billingSelectedDealerId,
        userId: user?.id || '',
        params
      })
    })()
  }, [billingSelectedDealerId, dateRange, startDate, endDate])

  useEffect(() => {
    if (isSuccess) {
      // График "Трафик на сайт"
      const trafficData = data?.datasets.find((item) => item?.label === 'Количество посетителей') || { data: [] }

      const trafficDataset = {
        labels: data?.labels || [],
        datasets: [
          {
            // @ts-ignore
            label: trafficData?.label,
            data: trafficData?.data,
            borderColor: '#223361',
            backgroundColor: '#ffffff',
            fill: {
              target: true,
              above: '#223361',
              below: '#223361'
            },
            pointRadius: 10
          }
        ]
      }

      // График "Просмотренные страницы авто"
      const viewedPagesData = data?.datasets.find((item) => item?.label === 'Количество посетителей АВН') || {
        data: []
      }
      const viewedPagesDataset = {
        labels: data?.labels || [],
        datasets: [
          {
            // @ts-ignore
            label: viewedPagesData?.label,
            data: viewedPagesData?.data,
            backgroundColor: '#f6b7c2'
          }
        ]
      }

      // График "Автомобили на сайте и добавленные tooltip"
      const currentVINData = data?.datasets.find((item) => item?.label === 'В данный момент на сайте VIN') || {
        data: []
      }
      const newVINData = data?.datasets.find((item) => item?.label === 'Количество новых VIN') || { data: [] }

      const currentAndNewVINDataset = {
        labels: data?.labels || [],
        datasets: [
          {
            // @ts-ignore
            label: currentVINData?.label,
            data: currentVINData?.data,
            borderColor: '#223361',
            borderWidth: 2,
            backgroundColor: 'rgba(34, 51, 97, 0.5)',
            pointRadius: 7
          },
          {
            // @ts-ignore
            label: newVINData?.label,
            data: newVINData?.data,
            borderColor: '#f6b7c2',
            borderWidth: 2,
            backgroundColor: 'rgba(246, 183, 194, 0.5)',
            pointRadius: 7
          }
        ]
      }

      // График Заявки
      const digitalDealRequests = data?.datasets.find((item) => item?.label === 'Заявки из цифровой сделки') || {
        data: []
      }
      const maintenanceCalculatorRequests = data?.datasets.find(
        (item) => item?.label === 'Заявки с калькулятора технического обслуживания'
      ) || {
        data: []
      }
      const bodyRepairCalculatorRequests = data?.datasets.find(
        (item) => item?.label === 'Заявки с калькулятора кузовного ремонта'
      ) || {
        data: []
      }
      const digitalDealServiceBooking = data?.datasets.find(
        (item) => item?.label === 'Запись на сервис из цифровой сделки'
      ) || {
        data: []
      }
      const callbackRequests = data?.datasets.find((item) => item?.label === 'Заявки обратный звонок') || {
        data: []
      }
      const showcaseRequests = data?.datasets.find((item) => item?.label === 'Заявки с витрины') || {
        data: []
      }
      const modelPageRequests = data?.datasets.find((item) => item?.label === 'Заявки на модель') || {
        data: []
      }
      const servicePlatformRequests = data?.datasets.find(
        (item) => item?.label === 'Заявки на сервис из платформы сайтов'
      ) || {
        data: []
      }
      const testDriveRequests = data?.datasets.find((item) => item?.label === 'Заявки на тест-драйв') || {
        data: []
      }

      interface Dataset {
        label: string
        data: number[]
      }

      const colorsForLabels: { [key: string]: string } = {
        'Заявки из цифровой сделки': 'rgba(34, 51, 97, 1)',
        'Заявки с калькулятора технического обслуживания': 'rgba(241, 136, 1, 1)',
        'Заявки с калькулятора кузовного ремонта': 'rgba(35, 35, 35, 1)',
        'Запись на сервис из цифровой сделки': 'rgba(0, 153, 204, 1)',
        'Заявки обратный звонок': 'rgba(124, 124, 250, 0.57)',
        'Заявки с витрины': 'rgba(92, 110, 23, 0.69)',
        'Заявки на модель': 'rgba(180, 48, 54, 0.79)',
        'Заявки на сервис из платформы сайтов': 'rgba(118, 130, 155, 0.9)',
        'Заявки на тест-драйв': 'rgba(132, 210, 6, 0.91)'
        // Добавить другие типы запросов и их цвета здесь
      }

      const currentRequestDataset = {
        labels: data?.labels || [],
        datasets: [
          digitalDealRequests,
          maintenanceCalculatorRequests,
          bodyRepairCalculatorRequests,
          digitalDealServiceBooking,
          callbackRequests,
          showcaseRequests,
          modelPageRequests,
          servicePlatformRequests,
          testDriveRequests
        ]
          // @ts-ignore
          .filter((dataset): dataset is Dataset => dataset != null && dataset.label != null)
          .map((dataset) => ({
            label: dataset.label,
            data: dataset.data,
            backgroundColor: colorsForLabels[dataset.label] || 'rgba(255, 99, 132, 0.6)', // стандартный цвет
            stack: 'stack1'
          }))
      }

      setTrafficGraphData(trafficDataset)
      setViewedPagesGraphData(viewedPagesDataset)
      setCurrentVINGraphData(currentAndNewVINDataset)
      setCurrentRequestGraphData(currentRequestDataset)
    }
  }, [data, isSuccess])

  const changeDateRange = (update: any) => {
    let updatedValue = update

    if (new Date(updatedValue[0]).getTime() === new Date(updatedValue[1]).getTime()) {
      updatedValue = [update[0], null]
    } else {
      updatedValue = update
    }

    setDateRange(updatedValue)
  }

  // const data = {
  //   labels: ['BMW', 'haval', 'sollers', 'mercedes-benz'],
  //   datasets: [
  //     {
  //       label: 'какой-то текст',
  //       data: [300, 50, 150, 45],
  //       backgroundColor: ['#223361', '#F18801', '#C4C4C4', '#232323'],
  //       hoverOffset: 4
  //     }
  //   ]
  // }
  const options = {
    type: 'doughnut',
    data: data,
    options: {}
  }

  const optionTraffic = {
    type: 'line',
    data: trafficGraphData,
    options: {
      plugins: {
        filler: {
          propagate: true
        }
      },
      pointBackgroundColor: '#ffffff',
      radius: 0,
      interaction: {
        intersect: true
      }
    }
  }
  const optionAutoInSite = {
    type: 'line',
    data: currentVINGraphData,
    options: {
      pointBackgroundColor: '#ffffff',
      radius: 0,
      interaction: {
        intersect: true
      }
    }
  }
  const optionViewedPages = {
    type: 'bar',
    data: viewedPagesGraphData,
    options: {
      responsive: true,
      scales: {
        y: {
          beginAtZero: true
        }
      }
    }
  }
  const optionRequest = {
    type: 'bar',
    data: currentRequestGraphData,
    options: {
      responsive: true,
      maintainAspectRatio: false
    },
    scales: {
      x: {
        stacked: true
      },
      y: {
        stacked: true,
        ticks: {
          stepSize: 10
        }
      }
    }
  }

  const handleCloseDatepicker = () => {
    if (!endDate) {
      return false
    } else {
      return true
    }
  }

  return (
    <Container bg={'white'} isAnalytics={true}>
      <AnalyticsInner>
        {isPdfLoader ? (
          <LoaderContainer
            style={{
              width: '100vw',
              height: '100vh',
              position: 'fixed',
              background: '#ffffff',
              top: '0',
              zIndex: '2',
              left: '0'
            }}
          >
            <Loader />
          </LoaderContainer>
        ) : null}
        <Header>
          <Buttons>
            <Button
              variant={'secondary'}
              size={'medium'}
              icon={pdf}
              onClick={() => {
                handleOpenPDF()
                if (userType !== 'internal') {
                  sendUserClickEvent('button_print_presentation_click')
                }
              }}
            >
              Скачать презентацию
            </Button>

            <Button
              variant={'secondary'}
              size={'medium'}
              icon={download}
              disabled={isDataLoader}
              onClick={() => {
                handleDownloadClick()
                if (userType !== 'internal') {
                  sendUserClickEvent('button_download_data_click')
                }
              }}
            >
              Скачать данные
            </Button>
            <Share>
              <Image src={share} alt="name" />
            </Share>
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update: any) => {
                changeDateRange(update)
              }}
              onBlur={handleCloseDatepicker()}
              isClearable={false}
              className={'udp'}
              customInput={<FilterByDateComponents />}
              locale={locale}
              calendarStartDay={1}
            />
          </Buttons>
        </Header>
        <PdfContainer id="pdf-place">
          <Title className="hidden">Персональная аналитика</Title>
          <SubTitle className="hidden">
            Мы регулярно обновляем данные аналитики и безопасно храним их. <br /> В данный момент аналитика работает в
            тестовом режиме
            {handleCloseDatepicker() ? (
              <p>
                с <b>{convertDate(startDate, '.')}</b> по <b>{convertDate(endDate, '.')}</b>
              </p>
            ) : (
              <p>
                за <b>{convertDate(startDate, '.')}</b>
              </p>
            )}
          </SubTitle>
          {/*<Row double={true} small={true}>*/}
          {/*  <Item double={true}>*/}
          {/*    <Name>*/}
          {/*      Выручка <Image src={iInCircleBlue} alt="tooltip" id="billing" />*/}
          {/*    </Name>*/}
          {/*    <Doughnut data={data} options={options} />*/}
          {/*  </Item>*/}
          {/*  <Item double={true}>*/}
          {/*    <Name>*/}
          {/*      Траты <Image src={iInCircleBlue} alt="tooltip" id="expense" />*/}
          {/*    </Name>*/}
          {/*    <Doughnut data={data} options={options} />*/}
          {/*  </Item>*/}
          {/*</Row>*/}
          {data && (
            <>
              <Row className="first-pdf-element" double={false}>
                <Item double={false}>
                  <Name className="charts-title">
                    Трафик на сайт{' '}
                    <TooltipWrapper>
                      <Image
                        src={iInCircleBlue}
                        alt="tooltip"
                        id="traffic"
                        onClick={() => handleTooltipClick('traffic')}
                      />
                      {tooltip === 'traffic' && (
                        <TooltipContent ref={tooltipRef}>
                          Количество уникальных пользователей на вашем сайте
                        </TooltipContent>
                      )}
                    </TooltipWrapper>
                  </Name>
                  <Line data={trafficGraphData} options={optionTraffic} />
                </Item>
              </Row>
              <Row className="flex-column" double={true}>
                <Item className="break" double={true}>
                  <Name className="charts-title">
                    Автомобили на сайте <br /> и добавленные{' '}
                    <TooltipWrapper>
                      <Image
                        src={iInCircleBlue}
                        alt="tooltip"
                        id="autoInSite"
                        onClick={() => handleTooltipClick('autoInSite')}
                      />
                      {tooltip === 'autoInSite' && (
                        <TooltipContent ref={tooltipRef}>
                          Количество уникальных автомобилей добавленных на вашей витрине
                        </TooltipContent>
                      )}
                    </TooltipWrapper>
                  </Name>
                  <Line data={currentVINGraphData} options={optionAutoInSite} />
                </Item>
                <Item className="break" double={true}>
                  <Name className="charts-title">
                    Просмотренные <br /> страницы авто{' '}
                    <TooltipWrapper>
                      <Image
                        src={iInCircleBlue}
                        alt="tooltip"
                        id="viewedPages"
                        onClick={() => handleTooltipClick('viewedPages')}
                      />
                      {tooltip === 'viewedPages' && (
                        <TooltipContent ref={tooltipRef}>
                          Количество уникальных пользователей посетившие страницы автомобилей
                        </TooltipContent>
                      )}
                    </TooltipWrapper>
                  </Name>
                  <Bar data={viewedPagesGraphData} options={optionViewedPages} />
                </Item>
              </Row>
              <Row className="break" double={false}>
                <Item double={false}>
                  <Name className="charts-title">
                    Заявки{' '}
                    <TooltipWrapper>
                      <Image
                        src={iInCircleBlue}
                        alt="tooltip"
                        id="request"
                        onClick={() => handleTooltipClick('request')}
                      />
                      {tooltip === 'request' && (
                        <TooltipContent ref={tooltipRef}>
                          Количество заявок со всех ваших форм и инструментов
                        </TooltipContent>
                      )}
                    </TooltipWrapper>
                  </Name>
                  <Bar data={currentRequestGraphData} options={optionRequest} />
                </Item>
              </Row>
            </>
          )}
        </PdfContainer>
      </AnalyticsInner>
      <ReactTooltip anchorId="expense" place="right" content="Траты" />
      <ReactTooltip anchorId="billing" place="right" content="Выручка" />
      <ReactTooltip anchorId="traffic" place="right" content="Количество посетителей на вашем сайте" />
      <ReactTooltip anchorId="autoInSite" place="right" content="Автомобили на сайте и добавленные" />
      <ReactTooltip anchorId="viewedPages" place="right" content="Просмотренные страницы авто" />
      <ReactTooltip anchorId="request" place="right" content="Заявки" />
      <IframeContainer>
        <iframe
          width={'100%'}
          height={'100%'}
          src={`https://datalens.yandex/obad7ddchcl4c?client_id=${billingSelectedDealerId}&embedded=1&_no_controls=1`}
        ></iframe>
      </IframeContainer>
    </Container>
  )
}
