import { createSlice } from '@reduxjs/toolkit'

import { Animation } from '../@types/animation'

const initialState = {
  isFixed: false,
  twistedAnimationDistance: 200
}

const animationSlice = createSlice({
  name: 'animation',
  initialState,
  reducers: {
    fixedBlock: (state, { payload }) => {
      state.isFixed = payload
    }
  }
})

export const { fixedBlock } = animationSlice.actions
export const selectAnimation = (state: { animation: Animation }) => state.animation
export default animationSlice.reducer
