import { ReactNode } from 'react'

import { AlertContainer, Content } from './style'

type ThemeAlert = 'success' | 'info' | 'warning' | 'error'

interface AlertProps {
  children: {
    text: ReactNode
  }
  theme: ThemeAlert
}

export const Alert: React.FC<AlertProps> = ({ children, theme }) => {
  const innerHTML = children.text ? { __html: children.text as string } : undefined

  return (
    <AlertContainer className={theme}>
      <Content dangerouslySetInnerHTML={innerHTML} />
    </AlertContainer>
  )
}

Alert.defaultProps = {
  theme: 'success'
}
