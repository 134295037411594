import { createApi } from '@reduxjs/toolkit/query/react'

import { LoginTrackerRequestType, LoginTrackerResponseType } from '../@types/trackerTypes'
import { UserLoginResponseType } from '../@types/userTypes'
import { customFetchBaseWithReauth } from '.'

export const trackerApi = createApi({
  reducerPath: 'trackerApi',
  baseQuery: (args, api, extraOptions) =>
    customFetchBaseWithReauth({ ...args, baseUrl: process.env.REACT_APP_API_URL }, api, extraOptions),
  endpoints: (builder) => ({
    loginTracker: builder.mutation<LoginTrackerResponseType, LoginTrackerRequestType>({
      query: ({ destination }) => ({
        url: `/login_tracker?destination=${destination}`,
        method: 'GET'
      }),
      transformResponse: (response: { data: LoginTrackerResponseType }, meta, arg) => response?.data
    }),
    loginTrackerByToken: builder.mutation<UserLoginResponseType, { token: string }>({
      query: ({ token }) => ({
        url: '/login/by_tracker',
        method: 'POST',
        body: {
          data: {
            token
          }
        }
      }),
      transformResponse: (response: { data: UserLoginResponseType }, meta, arg) => response?.data
    })
  })
})

export const { useLoginTrackerMutation, useLoginTrackerByTokenMutation } = trackerApi
