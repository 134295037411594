import styled from '@emotion/styled/macro'

export const Field = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;

  @media ${({ theme }) => theme.media.large} {
    flex-direction: column;
  }

  @media ${({ theme }) => theme.media.medium} {
    margin-bottom: 2.5rem;
  }
  @media ${({ theme }) => theme.media.small} {
    margin-bottom: 1.2rem;
  }
`

export const FieldInner = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 2.4rem;
  border-radius: 0.8rem;
  width: calc(50% - 1.2rem);

  &.home {
    width: 100%;
  }

  @media ${({ theme }) => theme.media.large} {
    width: 100%;
    margin-bottom: 2rem;
    padding-bottom: 1.5rem;
  }
  @media ${({ theme }) => theme.media.medium} {
    padding: 2.4rem 1.6rem 0 1.6rem;
  }

  button {
    @media ${({ theme }) => theme.media.small} {
      width: 100%;
      margin-bottom: 2.4rem;
    }
  }
`

export const TitlePopup = styled.div`
  ${({ theme }) => theme.title.h2};
  font-family: 'Helvetica', sans-serif;
  text-align: left;
  width: 100%;
  margin-bottom: 2.3rem;
`

export const Title = styled.div`
  font-size: 2.2rem;
  font-family: 'Helvetica', sans-serif;
  margin-bottom: 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    cursor: pointer;
  }
`

export const Notification = styled.div<{ isRed?: boolean; isGreenText?: boolean }>`
  background: rgba(196, 196, 196, 0.2);
  ${({ theme }) => theme.body.p5};
  padding: 1.2rem 2.4rem;
  margin-left: -2.4rem;
  margin-right: -2.4rem;
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;
  color: ${({ theme, isGreenText }) => (isGreenText ? theme?.colors?.success : theme?.colors?.basic)};
  svg,
  img {
    margin-right: 0.7rem;
    @media ${({ theme }) => theme.media.medium} {
      margin-right: 0.4rem;
    }

    circle,
    path,
    rect {
      stroke: ${({ isRed }) => (isRed ? '#E00909' : '#ccc')};
    }
  }

  @media ${({ theme }) => theme.media.medium} {
    margin-right: -1.6rem;
    margin-left: -1.6rem;
    padding: 1.2rem 1.6rem;
  }
`

export const NotificationListClients = styled.div<{ isRed?: boolean; isGreenText?: boolean }>`
  background: rgba(196, 196, 196, 0.2);
  ${({ theme }) => theme.body.p5};
  font-size: 1.6rem;
  font-weight: 400;
  padding: 1.2rem 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2.4rem;
  border-radius: 0.8rem;
  color: ${({ theme, isGreenText }) => (isGreenText ? theme?.colors?.success : theme?.colors?.basic)};
  svg,
  img {
    margin-right: 0.7rem;
    @media ${({ theme }) => theme.media.medium} {
      margin-right: 0.4rem;
    }

    circle,
    path,
    rect {
      stroke: ${({ isRed }) => (isRed ? '#E00909' : '#ccc')};
    }
  }

  @media ${({ theme }) => theme.media.medium} {
    padding: 1.2rem 1.6rem;
    line-height: 2.2rem;
  }

  svg {
    min-width: 20px;
  }
`

export const Block = styled.div`
  font-family: 'Helvetica', sans-serif;
  ${({ theme }) => theme.body.p3};
  color: ${({ theme }) => theme.colors.grey5};
  letter-spacing: -0.2px;
  margin-bottom: 2.1rem;
  &:last-of-type {
    margin-bottom: 0;
  }
`

export const SubTitle = styled.div`
  ${({ theme }) => theme.body.p2};
  color: ${({ theme }) => theme.colors.basic};
  margin-bottom: 0.3rem;
  display: flex;
  align-items: center;
`

export const FlexBlock = styled.div`
  display: flex;
`

export const WrapImg = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;

  @media ${({ theme }) => theme.media.small} {
    width: 2.1rem;
    height: 1.9rem;
  }
`

export const WrapButton = styled.div`
  display: flex;
  justify-content: flex-start;

  & a:link,
  & a:visited {
    font-size: 1.4rem;
    font-family: 'Helvetica', sans-serif;
    padding: 8px 1.5rem;
    display: flex;
    border: 1px solid #223361;
    color: #223361;
    font-weight: 700;
    line-height: 24px;
    border-radius: 8px;
    text-decoration: none;
    margin-left: 1.6rem;
    white-space: nowrap;
    justify-content: center;

    @media ${({ theme }) => theme.media.extraLarge} {
      width: 100%;
      margin-left: 0;
      margin-top: 1.6rem;
    }
  }

  @media ${({ theme }) => theme.media.extraLarge} {
    flex-wrap: wrap;

    button {
      max-width: none;
      width: 100%;
      @media ${({ theme }) => theme.media.medium} {
        margin-bottom: 0;
      }
    }
  }
  @media ${({ theme }) => theme.media.medium} {
    margin-bottom: 2.5rem;
  }
`

export const Balance = styled.div`
  margin-left: 1.6rem;
`
