import styled from '@emotion/styled/macro'

export const AnalyticsInner = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-direction: column;
  border-radius: 0.8rem;
  padding: 2.5rem 1.6rem 1.7rem;
  ${({ theme }) => theme.body.p2};
  overflow: hidden;
  height: 100%;
`

export const Title = styled.div<{ isDevelop?: boolean }>`
  ${({ theme }) => theme.title.h2};
  color: ${({ theme, isDevelop }) => (isDevelop ? 'transparent' : theme.colors.accent)};
  font-family: 'Helvetica', sans-serif;
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  cursor: pointer;
`

export const Rating = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-left: 5px;
  }
`

export const ChartContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  #htmlLegend ul {
    display: flex;
    flex-direction: column;
  }
`

export const Block = styled.div`
  padding-top: 1.6rem;
  /* TODO: удалить паддинг когда будут все данные в аналитике */
  padding-top: 5.6rem;
  display: flex;
  justify-content: start;
  flex-direction: column;
  flex: 1;
`

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.grey3};
  margin-bottom: 0.8rem;
  flex-wrap: wrap;
  gap: 0.6rem;
`

export const Value = styled.div`
  color: ${({ theme }) => theme.colors.basic};
  text-align: right;
  display: flex;
  align-items: center;
`
