import { createApi } from '@reduxjs/toolkit/query/react'

import {
  BillingClientInfoByIdResponseType,
  BillingUserClientsResponseType,
  ClientAddressByIdRequestType,
  ClientByIdResponseType,
  ClientUsersByIdResponseType,
  ResponsibleUserResponseType
} from '../@types/billingUserTypes'
import { customFetchBaseWithReauth } from '.'

export const billingUserApi = createApi({
  reducerPath: 'billingUserApi',
  baseQuery: (args, api, extraOptions) =>
    customFetchBaseWithReauth(
      {
        ...args,
        baseUrl: process.env.REACT_APP_BILLING_API_URL
      },
      api,
      extraOptions
    ),
  endpoints: (builder) => ({
    getBillingUserClients: builder.mutation<BillingUserClientsResponseType, {}>({
      query: () => ({
        url: '/user/clients',
        method: 'GET'
      })
    }),
    getBillingClientInfoById: builder.mutation<BillingClientInfoByIdResponseType, { id: string }>({
      query: ({ id }) => ({
        url: `/client/${id}/billing_info`,
        method: 'GET'
      })
    }),
    getBillingClientResponsibleUserById: builder.mutation<ResponsibleUserResponseType, { id: string }>({
      query: ({ id }) => ({
        url: `/client/${id}/responsible_user`,
        method: 'GET'
      })
    }),
    getBillingClientById: builder.mutation<ClientByIdResponseType, { id: string }>({
      query: ({ id }) => ({
        url: `/user/clients/${id}`,
        method: 'GET'
      }),
      transformResponse: (response: ClientByIdResponseType, meta, arg) => {
        response.is_forward_client = Boolean(response?.forward_client_id ? response?.forward_client_id > 0 : false)
        return response
      }
    }),
    updateBillingClientAddressById: builder.mutation<ClientByIdResponseType, ClientAddressByIdRequestType>({
      query: ({ id, body }) => ({
        url: `/client/${id}/address`,
        method: 'PATCH',
        body
      })
    }),
    postInvoicing: builder.mutation<any, { id: string; body: any }>({
      query: ({ id, body }) => ({
        url: `/client/${id}/docs/bills`,
        method: 'POST',
        body
      })
    }),
    getModulePackages: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `/client/${id}/modules`,
        method: 'GET'
      })
    }),
    getSupportPackages: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `/client/${id}/packages`,
        method: 'GET'
      })
    })
  })
})

export const {
  useGetBillingUserClientsMutation,
  useGetBillingClientInfoByIdMutation,
  useGetBillingClientResponsibleUserByIdMutation,
  useGetBillingClientByIdMutation,
  useUpdateBillingClientAddressByIdMutation,
  usePostInvoicingMutation,
  useGetModulePackagesMutation,
  useGetSupportPackagesMutation
} = billingUserApi
