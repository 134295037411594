import styled from '@emotion/styled/macro'

export const HeaderSettingsInner = styled.div`
  background: rgba(34, 51, 97, 0.1);
  border-radius: 1rem;
  padding: 2.4rem 13.7rem 0.9rem 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  margin-bottom: 3.2rem;
  @media ${({ theme }) => theme.media.medium} {
    padding: 2.4rem 5rem 0.7rem 1.6rem;
  }
  @media ${({ theme }) => theme.media.small} {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const Field = styled.div`
  font-size: 2rem;
  font-family: 'Helvetica', sans-serif;
  margin-right: 1rem;
  margin-bottom: 1.5rem;

  span {
    color: #888888;
  }

  @media ${({ theme }) => theme.media.tablet} {
    font-size: 1.6rem;
  }
`

export const User = styled.div<{ isBlack?: boolean }>`
  ${({ theme }) => theme.title.h2};
  font-weight: 400;
  color: ${({ isBlack, theme }) => (isBlack ? theme.colors.black : theme.colors.accent)};

  @media ${({ theme }) => theme.media.tablet} {
    font-size: 2.2rem;
  }
`

export const Label = styled.div`
  ${({ theme }) => theme.body.p3};
  color: ${({ theme }) => theme.colors.grey3};
  margin-top: 1rem;
  font-family: 'Open Sans', sans-serif;
  @media ${({ theme }) => theme.media.tablet} {
    margin-top: 0.7rem;
    font-size: 1.2rem;
  }
`

export const Position = styled.div`
  ${({ theme }) => theme.body.p2};
  margin-top: 0.5rem;
  font-family: 'Open Sans', sans-serif;
  @media ${({ theme }) => theme.media.tablet} {
    margin-top: 0;
    font-size: 1.2rem;
  }
`

export const Edit = styled.div`
  cursor: pointer;
  position: absolute;
  top: calc(50% - 1rem);
  right: 2.4rem;

  @media ${({ theme }) => theme.media.large} {
    top: 1.8rem;
    right: 1.8rem;
  }
`
