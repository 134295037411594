import styled from '@emotion/styled/macro'

export const NotificationInner = styled.div`
  display: flex;
  background: rgba(196, 196, 196, 0.2);
  border-radius: 0.8rem;
  padding: 1.6rem 5.6rem 1.6rem 5.6rem;
  ${({ theme }) => theme.body.p4};
  position: relative;
  margin-bottom: 3.2rem;
  img {
    position: absolute;
    &.close {
      right: 2.1rem;
      top: 2.1rem;
      cursor: pointer;

      @media ${({ theme }) => theme.media.medium} {
        right: 2.9rem;
      }
    }
    &.lock {
      left: 2rem;
      top: 1.7rem;

      @media ${({ theme }) => theme.media.medium} {
        left: 2.8rem;
      }
    }
  }

  @media ${({ theme }) => theme.media.medium} {
    border-radius: 0;
    margin-bottom: 2.4rem;
    padding: 16px 55px 16px 65px;
    min-height: 86px;
  }
`
