import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import MaskedInput from 'react-input-mask'
import { useSelector } from 'react-redux'

import { RegistrationFormStepMainData } from '../../@types/forms'
import { InputProps } from '../../@types/formsUI'
import { useUpdateUserDataMutation, useUserLoginMutation } from '../../api/userApi'
import { useAuth } from '../../hooks/useAuth'
import { BtnWrap, Fields } from '../../pages/registartion/style'
import { selectUser } from '../../store/userSlice'
import { Button } from '../../ui/button'
import { Input } from '../../ui/input'
import { Label } from '../../ui/label'
import { Warning } from '../../ui/warning/Warning'
import { getLocalStorageValue } from '../../utils/localStorageHelpers'
import { registrationStepMainDataValidationSchema } from '../../validation/registrationValidationSchema'

export const EditPersonalInfo: FC<{ closePopup: (val: boolean) => void }> = ({ closePopup }) => {
  const user_tokens = getLocalStorageValue('user_tokens')
  // @ts-ignore
  const { setUserData, login } = useAuth()
  const [userLogin, { data: userLoginData, error }] = useUserLoginMutation()

  const [updateUserData, { data: userData, isLoading: isLoadingUpdateUserData, error: updateUserError }] =
    useUpdateUserDataMutation()
  const user = useSelector(selectUser)
  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
    getValues,
    reset
  } = useForm({
    mode: 'all',
    resolver: yupResolver(registrationStepMainDataValidationSchema),
    defaultValues: {
      firstName: user?.attributes?.first_name,
      lastName: user?.attributes?.last_name,
      company: user?.attributes?.company,
      position: user?.attributes?.position,
      phone: user?.attributes?.phone
    } as RegistrationFormStepMainData
  })

  useEffect(() => {
    if (userData && !updateUserError) {
      setUserData(userData)
      closePopup(false)
    }
  }, [userData, updateUserError])

  useEffect(() => {
    if (userLoginData && !error) {
      login(userLoginData)
      reset()
    }
  }, [userLoginData, error])

  const onSubmit = async () => {
    await updateUserData({
      body: {
        data: {
          id: '',
          type: 'user',
          attributes: {
            first_name: getValues().firstName,
            last_name: getValues().lastName,
            company: getValues().company,
            position: getValues().position,
            phone: getValues().phone
          }
        }
      },
      auth_token: user_tokens?.auth_token
    })
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Fields>
        <Label>Имя</Label>
        <Input type="text" register={register} name={'firstName'} required />
        {errors?.firstName && <Warning status={'error'}>{errors?.firstName?.message}</Warning>}
      </Fields>

      <Fields>
        <Label>Фамилия</Label>
        <Input type="text" register={register} name={'lastName'} required />
        {errors?.lastName && <Warning status={'error'}>{errors?.lastName?.message}</Warning>}
      </Fields>

      <Fields>
        <Label>Компания</Label>
        <Input type="text" register={register} name={'company'} required />
        {errors?.company && <Warning status={'error'}>{errors?.company?.message}</Warning>}
      </Fields>

      <Fields>
        <Label>Должность</Label>
        <Input type="text" register={register} name={'position'} required />
        {errors?.position && <Warning status={'error'}>{errors?.position?.message}</Warning>}
      </Fields>

      <Fields>
        <Label>Телефон</Label>
        <Controller
          control={control}
          name="phone"
          render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
            <MaskedInput
              mask="+7 (999) 999-99-99"
              maskChar="_"
              alwaysShowMask={false}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              error={error}
              name={'phone'}
            >
              {(inputProps: InputProps) => <Input {...inputProps} register={register} />}
            </MaskedInput>
          )}
        />

        {errors?.phone && <Warning status={'error'}>{errors?.phone?.message}</Warning>}
      </Fields>

      <BtnWrap>
        <Button disabled={isLoadingUpdateUserData}>Сохранить</Button>
      </BtnWrap>
    </form>
  )
}
