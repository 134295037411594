import { DropdownElement } from '../@types/dropdown'

export function transformDropdownList(list: DropdownElement[]): DropdownElement[] | {}[] {
  const transformList: DropdownElement[] | {}[] = list.map((el: {}, i: number) => ({
    ...el,
    isActive: false
  }))

  return transformList
}
