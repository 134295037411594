import styled from '@emotion/styled/macro'

export const NotificationsHeader = styled.div`
  background-color: #ffffff;
  padding: 24px 24px 40px 24px;
  display: flex;
  align-items: center;
  gap: 24px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const NotificationsTitle = styled.h1`
  color: #000000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.48px;
  text-wrap: nowrap;
`

export const NotificationsGroup = styled.div`
  border-radius: 10px;
  overflow: hidden;
`

export const NotificationRow = styled.div`
  background: #f7f8fa;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #c4c4c4;
  cursor: pointer;

  p {
    font-size: 14px;
  }

  span {
    font-size: 12px;
    color: #232323;
    min-width: 65px;
    text-align: right;
  }

  &.new {
    background: #ffffff;

    p {
      font-weight: 600;
    }
  }
`

export const NotificationsHeaderGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.full {
    width: 100%;
  }
`

export const NotificationsMarkButton = styled.button`
  background: #ffffff;
  color: #223361;
  font-size: 12px;
  border: none;
  cursor: pointer;
  font-weight: 600;
  margin-bottom: -10px;

  @media (max-width: 768px) {
    margin-bottom: initial;
    padding: 0;
  }
`

export const NotificationsPagination = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`

export const NotificationsPaginationButton = styled.button`
  background: #ffffff;
  border: none;
  width: 24px;
  height: 24px;
  position: relative;
  padding: 0;
  cursor: pointer;

  &[disabled] {
    opacity: 0.4;
  }
`

export const NotificationsPaginationSize = styled.p`
  margin-right: 32px;
  font-size: 12px;
  line-height: 20px;
`

export const NotificationsModalTitle = styled.h2`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  color: #232323;
  margin-bottom: 24px;
`

export const NotificationsModalContent = styled.p`
  font-size: 14px;
  color: #232323;
`
