import React, { createContext, ReactNode, useState } from 'react'

interface AnalyticsContextType {
  buttonClicked: boolean
  triggerButtonClick: () => void
}

export const AnalyticsContext = createContext<AnalyticsContextType | undefined>(undefined)

export const AnalyticsProvider = ({ children }: { children: ReactNode }) => {
  const [buttonClicked, setButtonClicked] = useState(false)

  const triggerButtonClick = () => {
    setButtonClicked((prev) => !prev)
  }

  return <AnalyticsContext.Provider value={{ buttonClicked, triggerButtonClick }}>{children}</AnalyticsContext.Provider>
}
