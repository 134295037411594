import { DetailedHTMLProps, FC, ImgHTMLAttributes } from 'react'

import { Img } from './style'

type ImagesProps = {}

export const Image: FC<ImagesProps & DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>> = ({
  ...rest
}) => {
  return <Img {...rest} />
}
