// import { convertDate } from './convertDate'

export function setFileName(dealerName: String | undefined, dates: Array<string> | undefined) {
  const firstDate = dates?.[0]
  const lastDate = dates?.[dates.length - 1]
  return `analytics_${dealerName}_${firstDate}-${lastDate}.xlsx`
}

export function setFileDocumentName(documentId: string | number, date: string) {
  return `УПД_${documentId}_от_${date}.pdf`
}

export function downloadFile(blob: Blob, fileName: string) {
  const fileUrl = window?.URL?.createObjectURL(blob)
  const a = document.createElement('a')
  a.style.cssText = 'display: none'
  a.href = fileUrl
  a.download = fileName
  a.addEventListener(
    'click',
    function cleanUpHandler() {
      setTimeout(() => {
        URL.revokeObjectURL(fileUrl)
      }, 0)
    },
    false
  )
  document?.body?.append(a)
  a.click()
  a.remove()
}
