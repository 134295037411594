import { createApi } from '@reduxjs/toolkit/query/react'

import { customFetchBaseWithReauth } from '.'

export const documentsApi = createApi({
  reducerPath: 'documentsApi',
  baseQuery: (args, api, extraOptions) =>
    customFetchBaseWithReauth({ ...args, baseUrl: process.env.REACT_APP_BILLING_API_URL }, api, extraOptions),
  endpoints: (builder) => ({
    getDocsByID: builder.mutation<any, { id: number; params: URLSearchParams }>({
      query: ({ id, params }) => ({
        url: `/client/${id}/docs?${params}`,
        method: 'GET'
      })
    })
  })
})

export const { useGetDocsByIDMutation } = documentsApi
