import styled from '@emotion/styled/macro'

import { baseTheme } from '../../assets/styles/theme'

const buttonVariants = {
  default: {
    border: `1px solid ${baseTheme?.colors?.accent}`,
    background: baseTheme?.colors?.accent,
    color: baseTheme?.colors?.white,
    borderHover: `1px solid ${baseTheme?.colors?.accentDark}`,
    backgroundHover: baseTheme?.colors?.accentDark
  },
  secondary: {
    border: `1px solid ${baseTheme?.colors?.accent}`,
    background: 'transparent',
    color: baseTheme?.colors?.accent,
    borderHover: `1px solid ${baseTheme?.colors?.accent}`,
    backgroundHover: baseTheme?.colors?.white
  }
}

export const StyledButton = styled.button<{ variant: 'default' | 'secondary'; size?: string }>(
  ({ theme, variant, size }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.4rem;
  font-family: 'Helvetica', sans-serif;
  padding: ${size === 'medium' ? '0.5rem' : '1rem'} 2.5rem;
  border-radius: 0.8rem;
  line-height: 2.8rem;
  box-sizing: border-box;
  transition: 0.3s linear;
  border: ${buttonVariants[variant]?.border};
  background: ${buttonVariants[variant]?.background};
  color: ${buttonVariants[variant]?.color};
  min-width: 14.3rem;
  position: relative;
  white-space: nowrap;

  &[disabled] {
    cursor: default;
    background: ${theme?.colors?.grey};
    border: 1px solid ${theme?.colors?.grey};
  };
  &:not(.secondary):not([disabled]):hover {
    background: ${buttonVariants[variant]?.backgroundHover};
    border: ${buttonVariants[variant]?.borderHover};
  };

  @media ${theme.media.small} {
    padding: ${size === 'medium' ? '0.5rem' : '1rem'} 1.5rem;
    min-height: 50px;
  }

  &.show img {
    transform: rotate(180deg);
  }
`
)
